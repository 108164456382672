import React, { Component,useState, useEffect, useRef, useContext } from 'react';
import "../Home/Home.css"
import "./Prices.css"

import icon from "../../assets/icon-custom3.webp"
import icon2 from "../../assets/package-icon.webp"
import icon3 from "../../assets/customize-icon.webp"
import viewmore from "../../assets/view-more.webp"
import axios from 'axios';
import { date_format, peoples } from '../../utils/config';
import { addInCart, setDate, triggerNativeEventFor } from '../../utils/helper';
import { DatePicker } from 'antd';
import moment from 'moment';
import { toast } from 'react-hot-toast';
import { UserContext } from '../../Context/UserContext';

const Lessons = () => {
    const {cart, setCart, isManualBooking, showDrawer} = useContext(UserContext);
    const [lesson,setLesson] = useState('Surf');
    const [people,setPeople] = useState(1);
    const [amount,setAmount] = useState(0);
    const [duration,setDuration] = useState();
    const [result,setResult] = useState([]);
    const [lessonCategory,setLessonCategory] = useState([]);
    const [hoursRate,setHoursRate] = useState([]);
    const durationRef = useRef()
    const lessonsRef = useRef();
    const getUnique = (list) => {
        var distinct = []
        for (var i = 0; i < list.length; i++)
            if (!distinct.find(x => x.name == list[i].name))
                distinct.push({name:list[i].name,value :list[i].name
                    ,price:list[i].price,discount:list[i].discount
                })
        return distinct
    }

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BACKEND}/api/getLessons`).then(resp => {
            setResult(resp.data.slice());
            setLessonCategory(getUnique(resp.data))
            //setLesson("Surf")
        });
    },[])

    useEffect(() => {
        calculatePrice();
    },[lesson,people])

    useEffect(() => {
        if(hoursRate.length > 0 && duration){
            triggerNativeEventFor(durationRef.current,{ event: 'change', value: duration})
        }
    },[hoursRate,duration])

    const calculatePrice = () => {
        const product = lessonCategory.find(x => x.name == lesson);
        if(product){
            const list = [];
            for(let i = 1; i<= 10;i++){
                let disc = Math.round((product.price*people*i)*(product.discount/100),0);
                let price = (product.price*people*i)-(i > 1 ? disc : 0);
                list.push({'hr':i,'rate':product.pric,price,disc:disc,value:`${i}hr`,name:`${i} hr (€${price})`})
            }
            if(list.length > 0){
                setHoursRate(list);
            }
        }
    }

    const handleLesson = (e) => {
        setLesson(e.target.value)
        //calculatePrice()
    }

    const handlePeople = (e) => {
        setPeople(e.target.value)
        //calculatePrice()
    }

    const handleRate = (e) => {
        const price = e.target[e.target.selectedIndex].dataset.price
        setAmount(price);
        setDuration(e.target.value)
    }

    const imgStyle = {
        height: '85px',
    }

    const bookNow = async (e) => {
        e.preventDefault();
        const form = document.forms.lesson
        if(amount <= 0){
            toast.error('please select lesson')
            return;
        }else if(form.reservationDate.value === undefined || form.reservationDate.value === ""){
            toast.error('please select lesson date!.')
            return;
        }
        
        const details = {
                category:'lesson',
                title:'Lesson',
                people:form.people.value,
                lessonDetail:form.lessonDetail.value,
                duration:form.lessonDuration.value,
                reservationDate:setDate(form.reservationDate.value),
                amount:amount
            }
        console.log(cart,isManualBooking)
        const itemInCart = await addInCart(details,details.category,isManualBooking);
        if(itemInCart.status){
            setCart([...cart,details])
            toast.success('Item successfully add in cart.', {
                icon: '👏',
              });
              showDrawer(true);
        }else{
            toast.error(itemInCart.message)
        }
    }
    

    useEffect(() => {
        const timer = setTimeout(() => {
            if (window.location.hash === "#lessons") {
                if(lessonsRef.current){
                    lessonsRef.current.scrollIntoView();
                }
            }
          }, 1500);
        return () => clearTimeout(timer);
      }, []);


    if(lessonCategory.length === 0)
        return <></>
    return (
        <>
            <section className='lessons-sec-5 py-5' id='lessons' ref={lessonsRef}>
                <div class="container">

                    <div className='row' >
                        <div className='title py-4'>
                            <img src={icon} style={imgStyle} /><h1>LESSONS</h1>
                        </div>
                        <p className='sec-3-desc'>To book your lesson, please choose one of the packages or create a custom booking here. Your wetsuit and equipment are included in the price of the lesson!                        </p>
                        <p className='sec-3-subhead'>Please Choose:
                        </p>
                        <form name='lesson' onSubmit={bookNow}>
                            <div className="row">
                                <div className="col-sm-3">
                                    <div className="form-group">
                                        <label>No of Person</label>
                                        <select className="form-control" name='people' onChange={handlePeople}>
                                            <option key={'11'} value={''}>Please Select Person</option>
                                        {
                                            peoples.map(n => <option key={n} value={n}>{n} Person</option>)
                                        }
                                        </select>
                                    </div>

                                </div>
                                <div className="col-sm-3">
                                    <div className="form-group">
                                        <label>Lesson</label>
                                        <select className="form-control" name='lessonDetail' onChange={handleLesson}>
                                        <option key={'11'} value={''}> Select Option </option>

                                            {
                                                lessonCategory && 
                                                lessonCategory.map(x => <option value={x.value}>{x.name}</option>)
                                            }
                                            
                                            {/* <option value="Windsurf">Windsurf</option>
                                            <option value="Kite">Kite</option>
                                            <option value="Sup">Sup</option> */}
                                        </select>
                                    </div>

                                </div>
                                <div className="col-sm-3">
                                    <div className="form-group">
                                        <label>Hours</label>
                                        <select className="form-control" name='lessonDuration' ref={durationRef} onChange={handleRate}>
                                        <option key={'11'} value={''}> Select Option </option>

                                            {hoursRate.length > 0 &&
                                                hoursRate.map(a => <option data-price={a.price} value={a.value}>{a.name}</option>)
                                            }
                                        </select>
                                    </div>
                                    {/* text input */}

                                </div>
                                <div className='col-sm-3'>
                                    <div class="form-group">
                                        <label>First Meeting</label>
                                        <div class="input-group date" id="reservationdate" data-target-input="nearest">
                                        <DatePicker
                                            name="reservationDate"
                                            format={date_format}
                                        />
                                            {/* <input type="text" name='reservationDate' class="form-control datetimepicker-input" data-target="#reservationdate" />
                                            <div class="input-group-append" data-target="#reservationdate" data-toggle="datetimepicker">
                                                <div class="input-group-text"><i class="fa fa-calendar"></i></div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                                <div className='col-sm-3'>
                                    <div class="form-group">
                                        <label>Total Lesson Amount : <span className='yellow-bubble'>€{amount}</span></label>
                                    </div>
                                </div>
                            </div>
                            <button type="submit" className="btn book-now-btn"> <i className='fas fa-suitcase px-2'></i>ADD TO YOUR TRAVEL</button>
                        </form>
                    </div>



                </div>
            </section>
            <section className='prices-banner'></section>
        </>
    );
}

export default Lessons;
