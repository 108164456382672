import React, { useState,useEffect } from 'react';
import "../Home/Home.css"
import "../Contact/Contact.css"
import icon from "../../assets/Contact-page.webp"
import map from "../../assets/map.PNG"
import sendmessage from "../../assets/send_message.webp"
import axios from 'axios';
import { Link, useNavigate } from "react-router-dom";
import { Form, Button } from 'react-bootstrap';



const ContactForm = () => {
    const imgStyle = {
        height: '85px',

    }
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const navigate = useNavigate();

            const submitAccomodations= async (e) =>{
            e.preventDefault();
            const baseUrl = `${process.env.REACT_APP_BACKEND}/api/new-contact`;
           const data = {
                name:name,
                email: email,
                message:message
           };

           const result = await axios.post(baseUrl, data);
           if(result.status===200){
                navigate('/')
        }
    }
    return (
        <>
            <section className='form-sec py-5'>
                <div class="container">

                    <div className='row'>
                        <div className='py-4 title'>
                            <img src={icon} /><h1>ADDRESS</h1>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <p className='sec-3-subhead'>SIDI KAOUKI SURF STATION</p>
                            <p className='sec-3-desc py-4'>
                                <b> Sidi Kaouki, Essaouira 44000, Morocco</b><br />
                                <br />
                                Email: <span className='contact-details'>info@sidi-kaouki.com</span><br />
                                Phone: <span className='contact-details'>+212666619800, +4917678133254</span><br />
                                Call or write us on Whatsapp<br />

                            </p>
                        </div>
                        <div className='col-lg-6'>
                            <img src={map} alt="" className='contact-map' />
                        </div>
                    </div>
                    <div className='row'>
                        <Form onSubmit={submitAccomodations}>
                            <Form.Group>
                                <Form.Label>Name</Form.Label>
                                <Form.Control type="text"  id='name'  placeholder="Enter your name" required value={name} onChange={e => setName(e.target.value)} />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="email" id='email' placeholder="Enter your email" required value={email} onChange={e => setEmail(e.target.value)} />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Message</Form.Label>
                                <Form.Control as="textarea" rows="3" id='message' placeholder="Enter your message" required value={message} onChange={e => setMessage(e.target.value)}/>
                            </Form.Group>
                            {/* <img src={sendmessage}/> */}
                            <button type="submit" className="btn btn-primary">Save</button>

                        </Form>
                    </div>

                </div>
            </section>
            <section className='contact-map'>

            </section>
        </>
    );
}

export default ContactForm;
