import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

const List = () => {
  const [coupons, setCoupons] = useState([]);

  useEffect(() => {
    getCoupons();
  }, []);

  const getCoupons = async () => {
    let result = await fetch(`${process.env.REACT_APP_BACKEND}/api/coupon/getAll`);
    result = await result.json();
    setCoupons(result);
  };

  const deleteCoupon = async (id) => {
    let result = await axios.delete(`${process.env.REACT_APP_BACKEND}/api/coupon/delete/${id}`);
    if (result) {
        getCoupons();
    }
  };

  return (
    <div>
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Coupons</h1>
              </div>
              <div className="col-sm-2 float-sm-right">
                <Link to="/new-coupon">
                  <button type="button" className="btn btn-block bg-gradient-primary text-white">
                    <i className="fa fa-plus px-1"></i>
                    Add New
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </section>

        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    {coupons.length > 0 ? (
                      <table id="example1" className="table table-bordered table-striped">
                        <thead>
                          <tr>
                            <th>ID</th>
                            <th>Code</th>
                            <th>Description</th>
                            <th>Amount</th>
                            <th>Expiry</th>
                            <th>Count</th>
                            <th>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {coupons.map((coupon, index) => (
                            <tr key={coupon._id}>
                              <td>{index + 1}</td>
                              <td>{coupon.code}</td>
                              <td>{coupon.description}</td>
                              <td>{coupon.amount}</td>
                              <td>{coupon.expiry}</td>
                              <td>{coupon.count}</td>
                              <td>
                                <i className="fas fa-trash-alt px-2" onClick={() => deleteCoupon(coupon._id)}></i> 
                              </td>
                            </tr>
                          ))}
                        </tbody>
                        <tfoot>
                          <tr>
                            <th>ID</th>
                            <th>Code</th>
                            <th>Description</th>
                            <th>Amount</th>
                            <th>Expiry</th>
                            <th>Count</th>
                            <th>Actions</th>
                          </tr>
                        </tfoot>
                      </table>
                    ) : (
                      <div>No data available</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default List;
