import React, { Component } from 'react'
import favicon from "../../assets/favicon.webp"
import '../index.css'

export default class Sidebar extends Component {
  render() {
    return (
      <div>
        <aside className="main-sidebar sidebar-dark-primary elevation-4">
          {/* Brand Logo */}
          <a href="/" className="brand-link">
            <img src={favicon} alt="Sidi Kaouki" className="brand-image img-circle elevation-3" style={{ opacity: '.8' }} />
            <span className="brand-text font-weight-light">Sidi Kaouki</span>
          </a>
          {/* Sidebar */}
          <div className="sidebar">
            {/* Sidebar user panel (optional) */}
            <div className="user-panel mt-3 pb-3 mb-3 d-flex">
              <div className="image">
                <img src="dist/img/user2-160x160.jpg" className="img-circle elevation-2" alt="User Image" />
              </div>
              <div className="info">
                <a href="#" className="d-block">Felix Gerl</a>
              </div>
            </div>
            {/* SidebarSearch Form */}
            <div className="form-inline">
              <div className="input-group" data-widget="sidebar-search">
                <input className="form-control form-control-sidebar" type="search" placeholder="Search" aria-label="Search" />
                <div className="input-group-append">
                  <button className="btn btn-sidebar">
                    <i className="fas fa-search fa-fw" />
                  </button>
                </div>
              </div>
            </div>
            {/* Sidebar Menu */}
            <nav className="mt-2">
              <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                {/* Add icons to the links using the .nav-icon class
         with font-awesome or any other icon font library */}
                {/* <li className="nav-item">
          <a href="/dashboard" className="nav-link">
            <i className="nav-icon fas fa-tachometer-alt" />
            <p>
              Dashboard
            </p>
          </a>
         
        </li> */}
                <li className="nav-item">
                  <a href="/bookings" className="nav-link">
                    <i className="nav-icon fas fa-th" />
                    <p>
                      All Bookings
                    </p>
                  </a>
                </li>
                <li className="">
                  <a href="#" className="nav-link">
                    <i className="nav-icon fas fa-chart-pie" />
                    <p className='px-2'>
                      Price Management
                      <i className="right fas fa-angle-left" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <a href="/entities" className="nav-link">
                        <i className="nav-icon fas fa-th" />
                        <p>
                          Entities
                        </p>
                      </a>
                    </li>

                    <li className="nav-item">
                      <a href="/accomodations" className="nav-link">
                        <i className="nav-icon fas fa-th" />
                        <p>
                          Accomodations
                        </p>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="/packages" className="nav-link">
                        <i className="nav-icon fas fa-th" />
                        <p>
                          Packages
                        </p>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="/camps" className="nav-link">
                        <i className="nav-icon fas fa-th" />
                        <p>
                          Camps
                        </p>
                      </a>
                    </li>
                  </ul>
                </li>




                <li className="">
                  <a href="#" className="nav-link">
                    <i className="nav-icon fas fa-chart-pie" />
                    <p className='px-2'>
                      Site Management
                      <i className="right fas fa-angle-left" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">

                    <li className="nav-item">
                      <a href="/hotels" className="nav-link">
                        <i className="nav-icon fas fa-th" />
                        <p>
                          Hotels
                        </p>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="/packageslist" className="nav-link">
                        <i className="nav-icon fas fa-th" />
                        <p>
                          Packages
                        </p>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="/campslist" className="nav-link">
                        <i className="nav-icon fas fa-th" />
                        <p>
                          Camps
                        </p>
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="">
                  <a href="#" className="nav-link">
                    <i className="nav-icon fas fa-chart-pie" />
                    <p className='px-2'>
                      Form Entries
                      <i className="right fas fa-angle-left" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">

                    <li className="nav-item">
                      <a href="/contactlist" className="nav-link">
                        <i className="nav-icon fas fa-th" />
                        <p>
                          Contact Enquiries
                        </p>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="/newsletterlist" className="nav-link">
                        <i className="nav-icon fas fa-th" />
                        <p>
                          NewsLetter
                        </p>
                      </a>
                    </li>

                  </ul>
                </li>
                <li className="nav-item">
                  <a href="/posts" className="nav-link">
                    <i className="nav-icon fas fa-th" />
                    <p>
                      Posts
                    </p>
                  </a>
                </li>

                <li className="nav-item">
                  <a href="/coupons" className="nav-link">
                    <i className="nav-icon fas fa-th" />
                    <p>
                      Coupon
                    </p>
                  </a>
                </li>
                <li className="nav-item">
                  <a href="/booking" className="nav-link">
                    <i className="nav-icon fas fa-th" />
                    <p>
                      Booking
                    </p>
                  </a>
                </li>
              </ul>
            </nav>
            {/* /.sidebar-menu */}
          </div>
          {/* /.sidebar */}
        </aside>

      </div>
    )
  }
}
