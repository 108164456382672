import React, { Component,useState,useEffect, useRef } from 'react';
import "../Home/Home.css"
import "../Prices/Prices.css"
import { FaInstagram } from 'react-icons/fa';
import { Navbar, Nav, NavbarBrand, NavItem } from 'react-bootstrap';
import campf from "../../assets/camp-featured.webp"
import tree from "../../assets/tree.png"
import icon from "../../assets/package-icon.webp"
import learn from "../../assets/learn_more.webp"
import { Link } from 'react-router-dom';

const Packages = () => {
    const packagesRef = useRef()
    const imgStyle = {
        height: '85px',

    }
    const campfeatured = {
        width: '100%',
    }
    const [name, SetName] = useState([]);

    useEffect(() => {
      getAccomodations();
    }, [])
  
    const getAccomodations = async () => {
      let result = await fetch(`${process.env.REACT_APP_BACKEND}/api/package/getAll`);
      result = await result.json();
      SetName(result);
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            if (window.location.hash === "#packages") {
                if(packagesRef.current){
                    packagesRef.current.scrollIntoView();
                }
            }
            }, 1500);
        return () => clearTimeout(timer);
    }, []);
  
    return (
        <>
            {/* <section className='blue-bg-stripss'></section> */}

            <section className='packages-sec-3 py-5' id='packages' ref={packagesRef}>
                <div class="container">

                    <div className='row'>
                        <div className='title'>
                            <img src={icon} style={imgStyle} /><h1>PACKAGES</h1>
                        </div>
                        <div className='white-frame px-4 py-4'>

                        <p className='sec-3-subhead'>SURF ALL DAY ON THE AFRICAN COAST…</p>
                        <p className='sec-3-desc'>Shared joy is truly double the joy. Have fun meeting like-minded people and improve your surfing,  yoga, kite surfing, windsurfing, SUP or horseriding skills as you learn from the pros. Our attentive coaches will help you to push yourself whilst making you feel at ease in a comfortable and fun environment.

                        </p>
                        
                        </div>
                    </div>

                    <div className='row'>
                    {name.map((item, index) => (
                        <div className='col-lg-4'>
                        <div className='p-box'>
                            <img src={`${process.env.REACT_APP_BACKEND}/uploads/packages/${item.document}`} style={campfeatured} />
                            <h3>{item.name}</h3>
                            {/* <h6>18/09/2018 – 25/09/2018</h6> */}
                            <div className='p-box-desc'>
                                <p>{item.excerpt}
                                </p>
                            </div>
                            <Link to={"/package-detail/" + item._id}>
                            <img src={learn} /></Link>
                        </div>
                    </div>
                    ))}
                        

                    </div>
                    
                </div>
            </section>
        </>
    );
}

export default Packages;
