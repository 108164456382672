import React, { Component } from 'react';
import "../Home/Home.css"
import "../Offers/Offers.css"
import { FaInstagram } from 'react-icons/fa';
import { Navbar, Nav, NavbarBrand, NavItem } from 'react-bootstrap';
import slide1 from "../../assets/slide-1.png"
import wave from "../../assets/wave.png"

const Intro = () => {
    const imgStyle = {
        width: '500px',

    }
    return (
        <>

            <div class="jumbotron jumbotron-fluid sidi-hero hero-sec-one offers-hero">
                <div class="container">
                    <div className='sidi-hero-slider'>
                        <div className='row'>
                            <div className='col-lg-6'>
                                <h1 class="text-black">OFFERS</h1>
                                <img src={wave} style={imgStyle} />
                                <div class="row">
                                <div class="about-dot-inner"></div>
                                
                                    <div class="col-sm-2">
                                    <div class="about-dot-inner"></div>

                                    <a className='pricestop-a' href='/offers#camps'> 

                                        <h3>CAMPS</h3>
                                    </a>
                                        <div class="about-dot-inner"></div>
                                    </div>
                                    <div class="col-sm-3">
                                    <a className='pricestop-a' href='/offers#packages'> 

                                    <h3>PACKAGES</h3>
                                    </a>
                                        <div class="about-dot-inner"></div>
                                    </div>
                                    <div class="col-sm-2">
                                    <a className='pricestop-a' href='/offers#customized'> 
                                    <h3>CUSTOMIZED</h3></a>
                                    </div>
                                    
                                    


                                </div>
                            </div>
                            <div className='col-lg-6 '>


                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Intro;
