import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import "../Home/Home.css"
import "./Prices.css"
import slide1 from "../../assets/slide-1.png"
import wave from "../../assets/wave.png"

const Intro = () => {
    const imgStyle = {
        width: '500px',

    }
    return (
        <>

            <div class="jumbotron jumbotron-fluid sidi-hero hero-sec-one packages-hero">
                <div class="container">
                    <div className='sidi-hero-slider'>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <h1 class="text-black">PRICES</h1>
                                <img src={wave} style={imgStyle} />
                                <div class="row">
                                    <div class="col-lg-2">
                                    <a className='pricestop-a' href='/prices#rentals'> 
                                        <h3>RENTALS</h3></a>
                                        <div class="prices-dot-inner"></div>
                                    </div>
                                    <div class="col-lg-2">
                                    <a className='pricestop-a'  href='/prices#lessons'>
                                    <h3>LESSONS</h3></a>
                                        <div class="prices-dot-inner"></div>
                                    </div>
                                    <div class="col-lg-2">
                                     <a className='pricestop-a' href='/prices#accomodations'>
                                    <h3>ACCOMODATION</h3></a>
                                    <div class="prices-dot-inner"></div>

                                    </div>
                                    <div class="col-lg-2">
                                    <div class="prices-dot-inner"></div>

                                    <a className='pricestop-a' href='/prices#transfers'>

                                    <h3>TRANSFERS</h3></a>

                                    </div>
                                    <div class="col-lg-2">
                                   <a className='pricestop-a' href='/prices#camps'>
                                    <h3>CAMPS</h3></a>
                                    <div class="prices-dot-inner"></div>

                                    </div>
                                     <div class="col-lg-2">
                                     <a className='pricestop-a'  href='/prices#packages'>
                                    <h3>PACKAGES</h3></a>

                                    </div>
                                   
                                
                                
                                    


                                </div>
                            </div>
                            

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Intro;
