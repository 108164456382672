
import React,{useState,useEffect} from "react"
import Header from "../../../Components/Header/Header";
import Footer from "../../../Components/Footer/Footer";
import Intro from "./Intro";
import Desc from "./Desc";
import Gallery from "./Gallery";
import { Params , useParams } from "react-router-dom";

const Main=()=>{
    const params = useParams();
    const [result,setResult] = useState();
    const getTeamDetails = async () => {
        let res= await fetch(`${process.env.REACT_APP_BACKEND}/api/hotel/get/${params.id}`);
        res = await res.json();
        setResult(res);
    };
    useEffect(() => {
        getTeamDetails();
    },[])

    if(!result)
        return <></>
    return (
        <>
        <Header/>
        <React.Fragment>
            <Intro crousal={result.crousal}/>
            <Desc id={params.id} name={result.name} description={result.description} whatsincluded={result.whatsincluded} result={result}/>
            {result.gallery && <Gallery gallery={result.gallery}/>}
        </React.Fragment>
        <Footer/>
        </>
    )
}

export default Main;