import React, { Component , useState } from 'react';
import "../Home/Home.css"
import './About.css'
import { FaInstagram } from 'react-icons/fa';
import { Navbar, Nav, NavbarBrand, NavItem } from 'react-bootstrap';
import camel from "../../assets/camel2.webp"
import tree from "../../assets/three-trees.webp"
import iconcustom2 from "../../assets/icon-custom2.webp"

import icon from "../../assets/restaurant.webp"
import icon2 from "../../assets/package-icon.webp"
import icon3 from "../../assets/customize-icon.webp"
import viewmore from "../../assets/view-more.webp"
import Station1 from "../../assets/image-002.jpg"
import Station2 from "../../assets/image-001.jpg"


const Station = () => {
    const imgStyle = {
        height: '85px',

    }
    const styles ={
        width:'100%',
    }
    const [showSection, setShowSection] = useState(false);
    const toggleSection = () => {
        setShowSection(!showSection);
      };

    return (
        <>
            <section className='home-sec-5 py-5'>
                <div class="container">
                  
                <div className='row' id='station'>
                        <div className='title py-4'>
                            <img src={icon} /><h1>STATION</h1>
                        </div>
                        <p className='sec-3-subhead'>A MAGICAL PLACE WITH MUCH TO OFFER …</p>
                        <p className='sec-3-desc py-4'>20 km down the coast from Essaouira lies the laid-back Moroccan village of Sidi Kaouki. Here you  will find Sidi Kaouki Surf Station situated right on the beachfront. Whether you are looking for year-round waves, great wind conditions or a quick escape from city life, water sports lovers of all levels will find a unique African experience here in Sidi Kaouki.</p>
                    </div>
                    
                    <div className='row'>
                        <div className='col-lg-4 mt-2'>
                                <a onClick={toggleSection} className='see-direction'>LEARN MORE</a>
                        </div>
                    </div>                   
                </div>
              
                
            </section>
            <div
                    style={{
                    display: showSection ? 'block' : 'none',
                    transition: 'height 0.5s ease-out',
                    overflow: 'hidden',
                    marginTop:'5%'
                    }}
                >
                    <img src={Station1} style={styles}/>

            <section className='story-sec py-5'>
                <div class="container">
                <div class="title">
                    <h1>Our Story</h1>
                </div>
                    <div className='white-frame  px-4 py-4'>
                    <p>The story of Sidi Kaouki Surf Station begins in 1998, when Felix (now owner of the Surf Station) first visited the
tiny, rural, Berber town of Sidi Kaouki. Back when Felix first arrived there was no road, and no surf station, but he
was blown away by the quality of the surf, wind and the laidback lifestyle.</p>
<br/>
<p>On a return trip, seven years later, Felix decided to turn his love of this small town into a Surf Station so that he
and his guests could have the opportunity to windsurf in the beautiful conditions every day. After a lot of hard
work and signing a lot of paperwork, the station came to life.</p>
<br/>
<p>The Station, by surfers for surfers, began to take form. Windsurfing during the day, and cooking up burgers
behind the bar in the evening. The Station began to build up its reputation as a laid back, fun place to hang
out after a great day in the water. Soon, it grew into a place for all kinds of watersports, expanding into surfing,
kitesurfing and SUP. From the very start Sidi Kaouki Surf Station has had great cooperative relationships with
Starboard and Hot Sails Maui so that the centre could have the best possible equipment for all its visitors.</p>
<br/>
<p>Now, 10 years later, Sidi Kaouki Surf Station maintains its laid back attitude and continues to be a meeting place
for water enthusiasts from around the world. The Station now rents out a huge collection of gear for surfing,
kiting, windsurfing and SUP and welcomes guests of all levels and all ages. We are now able to provide first
class lessons with our trained, on-site instructors and in total we have a team of eight dedicated staff members,
ready to help you have the full Sidi Kaouki experience.
</p>
<br/>
<p>And what about the burgers? Our upstairs restaurant boasts a full menu of delicious, high quality food, seven
days a week. The food is served inside our beautiful building that is fully decorated from top to bottom with
inspiration drawn from African and surfer culture.</p>
</div>
                </div>
            </section>
            <img src={Station2} style={styles}/>
            <section className='story-station-sec py-5'>
                <div class="container">
                <div class="title">
                    <h1>STATION</h1>
                </div>
                <div className='white-frame px-4 py-4'>
                <p>
                Sidi Kaouki Surf Station offers surf lessons, equipment hire and many more activities. Dressing rooms, showers
and lockers are available to those looking to make the most of the ocean. 
                </p>
                <br/>
                <p>Upstairs on our breathtaking terrace, we welcome guests to eat, drink, relax, enjoy music, and take in the
fantastic ocean views. Enjoy a glowing African sunset or lounge in one of our hammocks with fresh mint tea.
Kick back, relax and chill out with family and friends in our comfortable and sociable environment with free wifi,
board games and music!</p>
</div>
<br/>
<br/>
                    

                </div>
            </section>
                    
                 </div>
          
        </>
    );
}

export default Station;
