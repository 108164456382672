import React, { Component } from 'react';
import "../Home/Home.css"
import { FaInstagram } from 'react-icons/fa';
import { Navbar, Nav, NavbarBrand, NavItem } from 'react-bootstrap';
import camel from "../../assets/camel2.webp"
import tree from "../../assets/three-trees.webp"
import iconcustom2 from "../../assets/icon-custom2.webp"

import icon from "../../assets/camps-icon.webp"
import icon2 from "../../assets/package-icon.webp"
import icon3 from "../../assets/customize-icon.webp"
import viewmore from "../../assets/view-more.webp"
import { Link } from 'react-router-dom';


const Offers = () => {
    const imgStyle = {
        height: '85px',

    }
    return (
        <>
                <section className='yellow-bg-strip'></section>
            <section className='home-sec-4 py-5'>
                <div class="container">
                    <div className='row'>
                        <div className='col-lg-6'>
                            <img src={tree} />
                        </div>

                        <div className='col-lg-6'>
                            <img src={camel} />
                        </div>

                    </div>
                    <div className='row'>
                        <div className='title py-5'>
                            <img src={iconcustom2} /><h1>OFFERS</h1>
                        </div>
                        <div className="dotted-line"></div>

                    </div>
                    <div className='row mt-4 '>
                        <div className='col-lg-4 white-frame px-4 py-4'>
                            <img src={icon}/>
                            <h1>CAMPS</h1>
                            <p>Shared joy is truly double the joy. Meet likeminded people, improve your surfing, yoga, kite surfing, windsurfing, SUP and horseriding skills and have fun as you learn from the pros in Sidi Kaouki. Our attentive coaches will help you push your boundaries while making you feel at ease and comfortable.</p>
                            <Link target={'_blank'} to='/prices#camps'>
                            <img src={viewmore}/></Link>
                        </div>
                        <div className='col-lg-4 white-frame px-4 py-4'>
                            <img src={icon2}/>
                            <h1>All-inclusive <br/>packages</h1>
                            <p>It has never been easier to book your dream holiday! Discover your perfect all-inclusive package while saving money and time. With pre-arranged accommodation, transfers, rental car, surf equipment and lessons in Sidi Kaouki, you can enjoy a hassle-free holiday that is tailored to your individual needs and expectations.</p>
                            <Link target={'_blank'} to='/prices#packages'>
                            <img src={viewmore}/></Link>

                        </div>
                        <div className='col-lg-4 white-frame px-4 py-4'>
                            <img src={icon3}/>
                            <h1>Customize <br/> your holiday</h1>
                            <p>You know what you want! Create very own surf holiday by booking your preferred transfer, hotel, surf equipment and lessons all together on our website. You can book directly online and enjoy a stress-free holiday, everything done within a few minutes! Feel free to customize your stay in Sidi Kaouki. </p>
                            <Link to='/prices'>
                            <img src={viewmore}/></Link>

                        </div>

                    </div>
                    
                    
                </div>
            </section>
        </>
    );
}

export default Offers;
