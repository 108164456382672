import { packages, camps, date_format } from "./config";
import Moment from 'moment';

export const triggerNativeEventFor = (elm, { event, ...valueObj }) => {
    if (!(elm instanceof Element)) {
      throw new Error(`Expected an Element but received ${elm} instead!`);
    }
  
    const [prop, value] = Object.entries(valueObj)[0] ?? [];
    const desc = Object.getOwnPropertyDescriptor(elm.__proto__, prop);
  
    desc?.set?.call(elm, value);
    elm.dispatchEvent(new Event(event, { bubbles: true }));
  };

  export const setDate = (date) => {
    return Moment(date, date_format).format('YYYY-MM-DD');
  }

  export const applyFormat = (key,value) => {
    if(['reservationDate','dateOfArrival','campStartDate'].find(x => x === key)){
      return Moment(value).format(date_format)
    }else if(['dateOfArrivalAndDeparture','bookingDate'].find(x => x === key)){
      let dates = value.split(' to ')
      let start = Moment(dates[0]).format(date_format);
      let end = Moment(dates[1]).format(date_format);
      return `${start} to ${end}`;
    }else if(key === 'accomodationType'){
      try{
        let accommodations = JSON.parse(window.sessionStorage.getItem('accommodations'));
        return accommodations[value];
      }catch(e){
        return value;
      }
    }else if(key === 'campDates'){
      let start = Moment(value.startDate).format(date_format);
      let end = Moment(value.endDate).format(date_format);
      return `${start} to ${end}`;
    }else{
      return value;
    }
  }

  export async function addInCart(item, category, isManualBooking){
    item['approveByAdmin'] = false
    let itemInCart = JSON.parse(localStorage.getItem(isManualBooking ? "cart:admin" : "cart"));
    if(itemInCart){
      if(!isAdmin() && !isManualBooking){
        const duplicate = itemInCart.cart.find(x => x.category == category);
      
        if(duplicate){
            return {status:false,message:'item already add in cart'}
        }
        const camp = await camps();
        const package1 = await packages();
        
        let check = {camp:false,package:false}
        
        check.camp = findInKeys(camp,category);
        check.package = findInKeys(package1,category);
  
        const packageFound = itemInCart.cart.find(x => {
          if(x.category === package1[category])
            return x;
        });
  
        if(check.camp){
          if(packageFound){
            return {status:false,message:'package already add in cart please remvove it first',item,packageFound}
          }
        }
  
        const campFound = itemInCart.cart.find(x => {
          if(x.category === camp[category])
            return x;
        });
  
        if(check.package){
          if(campFound){
            return {status:false,message:'camp already add in cart please remvove it first',item,item}
          }
        }
        const campOrPackage = itemInCart.cart.map(x => {
            if(Object.keys(package1).find(a => a == x.category) || Object.keys(camp).find(a => a == x.category)){
              return true
            }
        });
        
        if(campOrPackage.find(x => x === true)){
          return {status:false,message:'Either you book a camp or a  package or you make your custom booking '}
        }
        
        if((packageFound||campFound) && (!check.camp && !check.package)){
          return {status:false,message:'Either you book a camp or a  package or you make your custom booking '}
        }
        if((check.camp || check.package) && itemInCart.cart.length > 0){
          return {status:false,message:'Either you book a camp or a  package or you make your custom booking '}
        }
      }
      
      itemInCart.cart.push(item);
    }else{
      itemInCart = {cart : [item]}
    }
    localStorage.setItem(isManualBooking ? "cart:admin" : "cart", JSON.stringify(itemInCart));
    return {status:true,message:'item added in cart'};
  }

  function findInKeys(data,category){
    let found = false;
    Object.keys(data).map(x => {
      if(data[x] === data[category])
        found = true;
    });
    return found;
  }

  export function getCartAmount(isManualBooking){
    const itemInCart = JSON.parse(localStorage.getItem(isManualBooking ? "cart:admin" : "cart"));
    // const itemInCart = JSON.parse(localStorage.getItem("cart"));
    if(itemInCart){
      const sum = itemInCart.cart
        .map(obj => parseFloat(obj.amount))
          .reduce((accumulator, current) => accumulator + current, 0);
      
      const disount = getCoupon();
      const overrideAmt = getOverrideCartAmt();
      if(overrideAmt > 0 && isManualBooking)
        return overrideAmt;
      else
        return parseInt(sum - disount);
    }else{
      return 0;
    }
  }

  export function saveCoupon(code, discount, isManualBooking){
    localStorage.removeItem("coupon");
    const amount = getCartAmount(isManualBooking);
    let totalDiscount = parseInt(amount*(discount/100));
    const coupon = {code: code, discount: totalDiscount };
    localStorage.setItem("coupon", JSON.stringify(coupon));
    return coupon;
  }

  export function saveOverrideCartAmt(amount){
    localStorage.setItem("cartAmount", amount);
    localStorage.setItem("isOverrideAmt", true);
    return true;
  }

  export function getIsOverrideCartAmt(){
    const isOverride = localStorage.getItem("isOverrideAmt");
    if(isOverride && isOverride !== null){
      return isOverride;
    }
    return 0;
  }

  export function getOverrideCartAmt(){
    const overrideAmt = localStorage.getItem("cartAmount");
    if(overrideAmt && overrideAmt !== null){
      return parseInt(overrideAmt);
    }
    return 0;
  }

  export function getCoupon(){
    const coupon = JSON.parse(localStorage.getItem("coupon"));
    if(coupon){
      return parseInt(coupon.discount);
    }else{
      return 0;
    }
  }

  export const clearCart = () => {
    localStorage.removeItem('cart');
    localStorage.removeItem('cart:admin');
    localStorage.removeItem('coupon');
    localStorage.removeItem('isOverrideAmt');
    localStorage.removeItem('cartAmount');
    window.location.reload();
  }

  export const resetStorage = () => {
    window.sessionStorage.setItem('accommodations',null);
    window.sessionStorage.setItem('camps',null);
    window.sessionStorage.setItem('packages',null);
  }

  const isAdmin = () => {
    try {
      let loginUser = JSON.parse(localStorage.user)
      if (loginUser != 'null' && loginUser != undefined && loginUser.result) {
        return loginUser.result.fname.toLowerCase() === 'felix';
      }
    } catch (e) {
      return false;
    }
  }

  export {
    isAdmin
  }