import React,{useState,useEffect} from 'react';
import { useParams } from 'react-router-dom';
import "../../Home/Home.css"
import "./index.css"
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import sunset from '../../../assets/sunset.jpg'
import PackageForm from '../PackageForm';
import ReactHtmlParser from 'react-html-parser';

const Desc = ({description,whatsincluded,result,id}) => {

    const caroselStyle = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
      },
    };

    const imgStyle = {
        height: '85px',
    }
    const cStyle = {
        height: '250px',
    }
    const [name, setName] = useState("");
    const [isSuccess, setIsSuccess] = useState(false);
    const [excerpt, setexcerpt] = useState('');
    const [file, setFile] = useState();
    const [fileName, setFileName] = useState("");
    const [images, setImages] = useState([]);
    const params = useParams();

    return (
        <>
            <section className='Desc-sec py-5'>
                <div class="container">
                    <div className='row'>
                    <Carousel responsive={caroselStyle}
                    autoPlay
                    autoPlaySpeed={3000}
                    infinite
                    >
                        {
                            result.crousal.map(c => <div><img src={`${process.env.REACT_APP_BACKEND}/uploads/packages/${c}`} style={cStyle} alt="" srcset="" /></div>)
                        }
                    </Carousel>;

                    </div>

                    <div className='row white-frame lg:px-5 px-2 py-5'>
                        <div className='col-lg-6'>
                        <div className='title py-4'>
                            <h1>DESCRIPTION</h1>
                        </div>
                        <p className='desc-subhead'>
                        { ReactHtmlParser(description) }

                        </p>
                        
                        </div>
                        <div className='col-lg-6'>
                        <div className='title py-4'>
                            <h1>What’s included?</h1>            
                        </div>
                        <p className='desc-subhead'>
                        { ReactHtmlParser(whatsincluded) }

                            </p>
                            <PackageForm type={id} title={result.name} />
                        </div>
                    </div>



                </div>
            </section>
        </>
    );
}

export default Desc;
