import React, { Component, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import "../Footer/Footer.css"
import { Navbar, Nav, NavbarBrand, NavItem } from 'react-bootstrap';
import { FaInstagram, FaFacebook, FaYoutube } from 'react-icons/fa';
import { Form, Button } from 'react-bootstrap';
import axios from 'axios';

import Logo from "../../assets/favicon.webp"

const Footer = () =>  {
   
        const imgStyle = {
            width: '120px',
        };
    const [email, setEmail] = useState('');

    const navigate = useNavigate();

            const submitAccomodations= async (e) =>{
            e.preventDefault();
            const userID = JSON.parse(localStorage.getItem('user')).result._id;
            const baseUrl = `${process.env.REACT_APP_BACKEND}/api/new-newsletter`;
           const data = {
                email: email,
           };

           const result = await axios.post(baseUrl, data);
           if(result.status===200){
                navigate('/')
        }
    }

        return (
            <>
                <section className='footer py-5'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-6'>
                                <h1>SIDI KAOUKI SURF STATION</h1>
                            </div>
                            <div className='col-lg-6 text-right'>
                                <img src={Logo} style={imgStyle} />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-lg-6'>
                                <FaYoutube className='footer-social-icon' />
                                <FaInstagram className='footer-social-icon' />
                                <FaFacebook className='footer-social-icon' />
                            </div>
                        </div>
                        <div className='row py-4'>
                            <div className='col-lg-6'>
                                <h3>CONTACT US</h3>
                                <p>INFO@SIDI-KAOUKI.COM<br />
                                    (+212) 6666 198 00<br />
                                    Whatsapp: (+49) 17678 13 32 54</p>
                            </div>
                            <div className='col-lg-6 text-right'>
                                <h3>subscribe to our newsletter</h3>
                                <Form onSubmit={submitAccomodations} className='footer-subscribe'>
                           
                                            <Form.Group>
                                                <Form.Control type="email" id='email' className='footer-subscribe-feild' placeholder="Subscribe to Our Newsletter" required value={email} onChange={e => setEmail(e.target.value)} />
                                            </Form.Group>
                            
                            {/* <img src={sendmessage}/> */}
                            <button type="submit" className="btn btn-primary subscribe-now">Subscribe</button>

                        </Form>
                            </div>
                        </div>
                        <div className="footer-dotted-line"></div>
                        <div className='row'>
                            <div className='col-lg-8'>
                                <div className='copyright-menu'>
                                        <Link to='/about'>
                                        <a>ABOUT</a></Link>
                                        <Link to='/contact'>
                                        <a>CONTACT</a></Link>

                                        </div>
                            </div>
                            <div className='col-lg-4 text-right'>
                                <p className='text-white pt-2'>© 2023 SIDI KAOUKI SURF STATION</p>
                            </div>

                        </div>
                    </div>
                </section>
            </>
        );
    
}

export default Footer;