
import React, { useEffect, useState } from "react"
import Header from "../../../Components/Header/Header";
import Footer from "../../../Components/Footer/Footer";
import Intro from "./Intro";
import Desc from "./Desc";
import Gallery from "./Gallery";
import { useParams } from "react-router-dom";

const Main=()=>{
    const params = useParams();
    const [result,setResult] = useState();
    const getPackage = async () => {
        let res = await fetch(`${process.env.REACT_APP_BACKEND}/api/package/get/${params.id}`);
        res = await res.json();
        setResult(res)
      };
    
      useEffect(() => {
        getPackage();
      }, []);

    if(!result)
      return <></>
    return (
        <>
        <Header/>
        <React.Fragment>
            <Intro/>
            <Desc id={params.id} description={result.description} whatsincluded={result.whatsincluded} result={result}/>
            <Gallery gallery={result.gallery}/>
        </React.Fragment>
        <Footer/>
        </>
    )
}

export default Main;