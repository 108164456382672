import React, { Component , useState } from 'react';
import "../Home/Home.css"
import './About.css'
import { FaInstagram } from 'react-icons/fa';
import { Navbar, Nav, NavbarBrand, NavItem } from 'react-bootstrap';
import camel from "../../assets/camel2.webp"
import tree from "../../assets/three-trees.webp"
import iconcustom2 from "../../assets/icon-custom2.webp"

import icon from "../../assets/restaurant.webp"
import icon2 from "../../assets/package-icon.webp"
import icon3 from "../../assets/customize-icon.webp"
import viewmore from "../../assets/view-more.webp"
import Station1 from "../../assets/image-002.jpg"
import Condition1 from "../../assets/condition.jpg"


const Condition = () => {
    const imgStyle = {
        height: '85px',

    }
    const styles ={
        width:'100%',
    }
    const [showSection, setShowSection] = useState(false);
    const toggleSection = () => {
        setShowSection(!showSection);
      };

    return (
        <>
          
            <div>
                    
            <img src={Condition1} style={styles}/>
            <section className='story-station-sec py-5' id='condition'>
                <div class="container">
                <div class="title">
                    <h1>SURF</h1>
                </div>
                <h5>" SIDI KAOUKI IS AL LONG OPEN BEACH BREAK WITH LOADS OF PUNCHY
                        PEAKS AND A BIT OF REEF AT THE RIVER MOUTH AND SOUTH
                        END. GOOD SHAPE ON THE RIGHT SWELLS AS LONG AS WIND IS E
                        QUADRANT. "</h5>
                    <br/>
                    <br/>

                    <div className='white-frame px-4 py-4'>
                <h4>SEASONAL CONDITIONS</h4>
                <br/>
                <p>Sidi Kaouki’s exposed beach break has reliable surf, which works year-round and is unaffected by the tide. It
tends to receive a mix of ground and wind swells, with offshore winds blowing from the south. With a short car
ride, the local area offers keen surfers numerous breaks to choose from.
</p>
<br/>
<p>In the 5 km long bay you will find enough spots to ride on the clean shore broken waves, which are usually
between 1-3 m high. There are not many spots in the world where it is so comfortable to hit the waves, no
dangers of rocks, shells, coral, sharks etc. There’s fine sand beach as far as the eyes can take you</p>
<br/>
<p>Being on the Atlantic coast, the waves provide a refreshing escape from the Moroccan heat! Lower temperatures
in the water mean that a 3/2 wetsuit is recommended in summer and a 4/3 in winter. Our local breaks are free
from crowds and offer a chance to surf in beautiful, untouched surroundings.</p>
</div>
                </div>
            </section>
                    
                 </div>
          
        </>
    );
}

export default Condition;
