import React, { Component } from 'react';
import "../Home/Home.css"
import "../Restaurant/Restaurant.css"
import icon from "../../assets/restaurant.webp"



const EatEnjoy = () => {
    const imgStyle = {
        height: '85px',

    }
    return (
        <>
            <section className='eat-sec py-5'>
                <div class="container">
                  
                <div className='row'>
                        <div className='title py-4'>
                            <img src={icon} /><h1>EAT & ENJOY</h1>
                        </div>
                        <div className='white-frame px-4 py-4'>
                        <p className='sec-3-subhead'>EAT & ENJOY AT OUR ROOFTOP TERRACE…</p>
                        <p className='sec-3-desc py-4'>Upstairs on our breathtaking terrace, we welcome guests to eat, drink, relax, enjoy music  and take in the fantastic ocean views. Enjoy a glowing African sunset or lounge in one of our hammocks with fresh mint tea. Kick back, relax and chill out with family and friends in our comfortable and sociable environment with free wifi, board games and music!</p>                    
                        </div>
                        </div>                  
                        
                </div>
            </section>
        </>
    );
}

export default EatEnjoy;
