import React, { useContext, useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes, Switch } from 'react-router-dom';
import logo from './logo.svg';
import './App.css';
import Home from './Pages/Home/index';
import About from './Pages/About/Index'
import RESTAURANT from './Pages/Restaurant/index'
import Contact from './Pages/Contact/index'
import Login from './Pages/Login/index'
import Offers from './Pages/Offers/index'
import Packages from './Pages/Prices/index';
import RebaliRiads from './Pages/Accomodations/Rebali Riads/index';
import Feru from './Pages/Accomodations/Feru/index';
import AbdlaApartment from './Pages/Accomodations/Apartments Abdla/index';
import Windykaouki from './Pages/Accomodations/Windy Kaouki/index';
import Marabout from './Pages/Accomodations/Marabout/index';
import Accodetail from './Pages/Accomodations/Accomodation-detail/Main';
import Pkgdetail from './Pages/Packages/Packages-detail/Main';
import Campdetail from './Pages/Camps/Camps-detail/Main';

import BudgetPackage from './Pages/Packages/Budget Package/index';
import FamilyPackage from './Pages/Packages/Family Package/index';
import AdventurePackage from './Pages/Packages/Adventure Package/index';
import CouplePackage from './Pages/Packages/Couples Package/index';
import LuxuryPackage from './Pages/Packages/Luxury Package/index';
import SHRCAMP from './Pages/Camps/SURF & HORSE RIDING CAMP/index';
import SYCAMP from './Pages/Camps/SURF AND YOGA CAMP/index';
import CAPCAMP from './Pages/Camps/Capoeira/index';
import WSFSCAMP from './Pages/Camps/WINDSURF, SURF, FOIL & SUP CAMP/index';
import TravelPackage from './Pages/Travel-Package/Index'
import Dashboard from './Dashboard/Home/index'
import Entity from './Dashboard/Datatable/index'
import NewEntity from './Dashboard/Entity/index'
import Checkout from './Pages/Checkout/Index'
import PrivateRoute from './PrivateRoute';
import UpdateIndex from './Dashboard/Entity/UpdateIndex';
import NewAccomodations from './Dashboard/Accomodations/index';
import List from './Dashboard/Accomodations/Datatable/index';
import UpdateAccomodations from './Dashboard/Accomodations/Update/index';
import NewHotel from './Dashboard/Hotel/index';
import HotelList from './Dashboard/Hotel/Datatable/index';
import UpdateHotel from './Dashboard/Hotel/Update/index';
import NewPackage from './Dashboard/Packages/Index';
import Packageslist from './Dashboard/Packages/Datatable/Index';
import UpdatePackage from './Dashboard/Packages/Update/Index';
import Camps from './Dashboard/Camps/Datatable/Index';
import NewCamps from './Dashboard/Camps/Index';
import UpdateCamps from './Dashboard/Camps/Update/Index';
import Allbookings from './Dashboard/Bookings/index';
import CheckoutSuccess from './Pages/Checkout/CheckoutSuccess';
import Packageslists from './Dashboard/NewPackages/Datatable/index';
import NewPackages from './Dashboard/NewPackages/index';
import Campslists from './Dashboard/NewCamps/Datatable/index';
import Newcamps from './Dashboard/NewCamps/index';
import PagesList from './Dashboard/Pages/Datatable/index';
import NewPages from './Dashboard/Pages/index';
import UpdateHome from './Dashboard/Pages/Update/index';
import ContactList from './Dashboard/ContactEntries/Datatable/index';
import NewsletterList from './Dashboard/NewsletterEntries/Datatable/index';
import BlogList from './Dashboard/NewBlog/Datatable/index';
import NewBlog from './Dashboard/NewBlog/index';
import BlogDetail from './Pages/Blog-detail/index';
import UpdateP from './Dashboard/NewPackages/Update/index';
import UpdateC from './Dashboard/NewCamps/Update/index'
import ScrollToTop from './ScrollToTop';
import toast, { Toaster } from 'react-hot-toast';
import NewCoupon from './Dashboard/Coupon/index';
import Coupon from './Dashboard/Coupon/Datatable/index';
import ManualBooking from './Dashboard/ManualBooking';
import { UserContext, UserContextProvider } from './Context/UserContext';

function App(props) {
  const auth = localStorage.getItem('user')
  

  return (
      <UserContextProvider>
    <BrowserRouter>
      <ScrollToTop />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/restaurant" element={<RESTAURANT />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/offers" element={<Offers />} />
          <Route exact path="/booking" element={<ManualBooking />} />
          <Route exact path="/prices" element={<Packages />} />
          <Route exact path="/budget-package" element={<BudgetPackage />} />
          <Route exact path="/family-package" element={<FamilyPackage />} />
          <Route exact path="/adventure-package" element={<AdventurePackage />} />
          <Route exact path="/couple-package" element={<CouplePackage />} />
          <Route exact path="/luxury-package" element={<LuxuryPackage />} />
          <Route exact path="/surf-horse-riding-camp" element={<SHRCAMP />} />
          <Route exact path="/surf-and-yoga-camp" element={<SYCAMP />} />
          <Route exact path="/capoeira-camp" element={<CAPCAMP />} />
          <Route exact path="/windsurf-surf-foil-sup-camp" element={<WSFSCAMP />} />
          <Route exact path="/travelpackage" element={<TravelPackage />} />
          <Route exact path="/rebali-riads" element={<RebaliRiads />} />
          <Route exact path="/feru" element={<Feru />} />
          <Route exact path="/abdla-apartments" element={<AbdlaApartment />} />
          <Route exact path="/windy-kaouki" element={<Windykaouki />} />
          <Route exact path="/marabout" element={<Marabout />} />
          <Route exact path="/accomodation-detail/:id" element={<Accodetail />} />
          <Route exact path="/package-detail/:id" element={<Pkgdetail />} />
          <Route exact path="/camp-detail/:id" element={<Campdetail />} />
          <Route exact path="/blog-detail/:id" element={<BlogDetail />} />
          <Route exact path="/checkout" element={<Checkout />} />
          <Route exact path="/paymentConfirm/:id" element={<CheckoutSuccess />} />

          <Route element={<PrivateRoute />}>
            <Route exact path="/dashboard" element={<Dashboard />} />
            <Route exact path="/entities" element={<Entity />} />
            <Route exact path="/new-entity" element={<NewEntity />} />
            <Route exact path="/update-entity/:id" element={<UpdateIndex />} />
            <Route exact path="/new-accomodations" element={<NewAccomodations />} />
            <Route exact path="/accomodations" element={<List />} />
            <Route exact path="/update-accomodations/:id" element={<UpdateAccomodations />} />
            <Route exact path="/new-hotel" element={<NewHotel />} />
            <Route exact path="/hotels" element={<HotelList />} />
            <Route exact path="/update-hotel/:id" element={<UpdateHotel />} />
            <Route exact path="/new-package" element={<NewPackage />} />
            <Route exact path="/packages" element={<Packageslist />} />
            <Route exact path="/update-packages/:id" element={<UpdatePackage />} />
            <Route exact path="/update-package/:id" element={<UpdateP />} />
            <Route exact path="/camps" element={<Camps />} />
            <Route exact path="/new-camps" element={<NewCamps />} />
            <Route exact path="/update-camps/:id" element={<UpdateCamps />} />
            <Route exact path="/bookings/" element={<Allbookings />} />
            <Route exact path="/packageslist" element={<Packageslists />} />
            <Route exact path="/newpackages" element={<NewPackages />} />
            <Route exact path="/campslist" element={<Campslists />} />
            <Route exact path="/newcamps" element={<Newcamps />} />
            <Route exact path="/pageslist" element={<PagesList />} />
            <Route exact path="/newpages" element={<NewPages />} />
            <Route exact path="/update-home/:id" element={<UpdateHome />} />
            <Route exact path="/contactlist" element={<ContactList />} />
            <Route exact path="/newsletterlist" element={<NewsletterList />} />
            <Route exact path="/posts" element={<BlogList />} />
            <Route exact path="/new-post" element={<NewBlog />} />
            <Route exact path="/update-p/:id" element={<UpdateP />} />
            <Route exact path="/update-c/:id" element={<UpdateC />} />
            <Route exact path="/new-coupon" element={<NewCoupon />} />
            <Route exact path="/coupons" element={<Coupon />} />
          </Route>
        </Routes>
      <Toaster position="top-right" />
    </BrowserRouter>
      </UserContextProvider>
  );
}
export default App;
