import React, { Component,useState, useEffect, useRef, useContext } from 'react';
import axios from 'axios';
import { date_format, days } from '../../utils/config';
import { addInCart, setDate, triggerNativeEventFor } from '../../utils/helper';
import { DatePicker } from 'antd';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import { toast } from 'react-hot-toast';
import { UserContext } from '../../Context/UserContext';

const AccommodationForm = ({type,title}) => {
    const {cart, setCart, isManualBooking, showDrawer} = useContext(UserContext);
    const category = type
    const [result,setResult] = useState([])
    const [peoples,setPeoples] = useState([]);
    const [people,setPeople] = useState();
    const [hotels,setHotels] = useState([]);
    const [accomodation,setAccomodation] = useState([]);
    const [extraService,setExtraService] = useState([]);
    const [accType,setAccType] = useState();
    const [addService,setAddService] = useState();
    const [amount,setAmount] = useState(0)
    const [days,setDays] = useState(0)
    const peopleRef = useRef()
    let [arrivalDeparture,setArrivalDeparture] = useState({
        startDate:new Date(),
        endDate:new Date(),
    })

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BACKEND}/api/accomodation/getByHotel/${category}`).then(resp => {
            setResult(resp.data.slice());
        });
    },[])

    useEffect(() => {
        const getHotels = async () => {
            let result = await fetch(`${process.env.REACT_APP_BACKEND}/api/hotel/getAll`);
            result = await result.json();
            setHotels(result);
          }
          getHotels();
    }, [])
    
    useEffect(() => {
        if(result.length>0){
            const p = []
            const ac = []
            const et = []
            result.map(x => {
                ac.push({accType:x.accType,charges:x.roomPrice})
                p.push(x.people)
                if(x.addService !== ""){
                    et.push({addService:x.addService,addCharges:x.addCharges})
                }
            })
            setPeoples([...new Set(p)])
            setAccomodation([...new Set(ac)])
            setExtraService([...new Set(et)])
        }
    },[result])

    const handleAccomodotion = (e) => {
        setAccType(e.target.value)
    }

    const handleExtra = (e) => {
        setAddService(e.target.value)
    }

    const handlePeople = (e) => {
        setPeople(e.target.value);
    }

    const getData = () => {
        let data;
        if(accType && people > 0) 
            data = result.find(x => x.accType == accType && people == x.people);
        else
        data = result.find(x => x.people == people);
        if(data){
            setAmount((data.roomPrice*days));
            //setAccomodation(undefined)
        }
    }

    useEffect(() => {
        if(people > 0){
            const acc = []
            result.filter(x => x.people == people).map(a => {
                acc.push({accType:a.accType,charges:a.roomPrice})
            })
            setAccomodation(acc)
        }
        else{
            triggerNativeEventFor(peopleRef.current,{ event: 'change', value: 0})
        }
        getData();
    },[people,arrivalDeparture])

    useEffect(() => {
        if(people > 0){
            const add = []
            result.filter(x => x.people == people).map(a => {
                if(a.addService !== ""){
                    add.push({accType:a.accType,charges:a.roomPrice})
                }
            })
            setExtraService(add)
        }
        getData();
    },[accType])

    const handleCallback = (start, end, label) => {
        console.log(start, end, label);
      }

    const handleEvent = (event, picker) => {
        // console.log(event.target);
        const duration = moment(picker.endDate).diff(picker.startDate, 'days');
        setDays(duration+1)
        setArrivalDeparture(picker)
        //console.log(picker.startDate,picker.endDate);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const form = document.forms.accoForm
        if(amount <= 0){
            toast.error('please select accommodation first')
            return;
        }else if(arrivalDeparture.startDate === undefined || arrivalDeparture.startDate === ""){
            toast.error('please select accommodation date!.')
            return;
        }
        
        const details = {
                category:type,
                title:title,
                people:form.people.value,
                accType:form.accType.value,
                days:days,
                bookingDate:`${setDate(arrivalDeparture.startDate)} to ${setDate(arrivalDeparture.endDate)}` ,
                amount:amount
            }
        
        const itemInCart = await addInCart(details,title, isManualBooking);
        if(itemInCart.status){
            setCart([...cart,details]);
            toast.success('Item successfully add in cart.', {
                icon: '👏',
              });
            showDrawer(true);
        }else{
            toast.error(itemInCart.message)
        }
    }

    return(
        <form name="accoForm" onSubmit={handleSubmit}>
            <div class="form-group">
                <label>Date:</label>
                {/* <DateRangePicker format={date_format} onEvent={handleEvent} onCallback={handleCallback}>
                    <input />
                </DateRangePicker> */}
                 <DateRangePicker
                 onEvent={handleEvent}
          initialSettings={{
            startDate: moment().format("DD-MM-YYYY"), // Set the default start date
            endDate: moment().add(1, "days").format("DD-MM-YYYY"), // Set the default end date
            locale: {
              format: "DD-MM-YYYY", // Enforce the DD-MM-YYYY format
              applyLabel: "Apply",
              cancelLabel: "Cancel",
              daysOfWeek: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
              monthNames: [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December",
              ],
            },
          }}
          onCallback={(start, end) => {
            handleEvent("",{endDate: end, startDate: start})
            console.log(`Range Selected: ${start.format("DD-MM-YYYY")} - ${end.format("DD-MM-YYYY")}`);
          }}
        >
          <input 
            placeholder="Select Date Range" 
            className="form-control" 
            // onChange={handleEvent}
            readOnly // Prevent user edits directly
          />
        </DateRangePicker>
            </div>
            <div className="form-group">
                <label>No of Person</label>
                <select className="form-control" name='people' ref={peopleRef} onChange={handlePeople}> 
                    <option value={''}>Please Select Person</option>
                {peoples.length > 0 &&
                    peoples.map(n => <option key={n} value={n}>{n} Person</option>)
                }
                </select>
            </div>
            <div className="form-group">
                <label>Accommodation</label>
                <select className="form-control" name='accType' onChange={handleAccomodotion}>
                <option value={''}>Select Option</option>
 
                {accomodation.length > 0 &&
                    accomodation.map((n,i) => <option key={i} data-price={n.charges} value={n.accType}>{n.accType}(Per Room Per Night €{n.charges})</option>)
                }
                </select>
            </div>
            {/* <div className="form-group">
                <label>Extra Service</label>
                <select className="form-control" name='extraService' onChange={handleExtra}>
                    {extraService.map((x) => (
                    <option key={x.addService} value={x.addService} data-price={x.addCharges}>
                        {x.addService}(+{x.addCharges})
                    </option>
                    ))}
                </select>
            </div> */}
            <div class="form-group">
                <label>Total Amount: <span className='yellow-bubble'>€{amount}</span></label>
                {/* <p> <b> Total amount per  room per night </b></p> */}

            </div>
            <button type="submit" className="btn book-now-btn"> <i className='fas fa-suitcase px-2'></i>ADD TO YOUR TRAVEL</button>

            </form>
    )
}

export default AccommodationForm;