import React, { useState, useEffect, useContext } from 'react';
import "../Header/Header.css"
import { Navbar, Nav, NavbarBrand, NavItem, Container, Image } from 'react-bootstrap';
import Logo from "../../assets/logo.png"
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Drawer from '../Drawer/Drawer';
import { camps, packages,accommodationList } from '../../utils/config';
import { UserContext } from '../../Context/UserContext';

camps()
packages()
accommodationList()

const Header = ({ ...props }) => {
  const {cart, setCart, isManualBooking, setIsManualBooking, user } = useContext(UserContext);
  // console.log(cart);
  const [show, setShow] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [itemInCart,setItemInCart] = useState(cart)
  const [isAdmin,setIsAdmin] = useState(false)
  
  useEffect(() => {
    if(cart){
      setItemInCart(cart)
    }
    setIsManualBooking(false);
  },[cart])

  useEffect(() => {
    const user = JSON.parse(localStorage.user !== undefined ? localStorage.user : null);
    if(user != null && user.result){
      setIsAdmin(user.result.fname === 'felix')
    }
    const handleScroll = () => {
      if (window.pageYOffset > 50) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    }
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    }
  }, []);

    return (
        <>
        {isAdmin && <p className='admin-indication'>You are login as admin | You can Make Custom Bookings For your Customer</p>}
        <Navbar expand="lg" className={`main-nav-sidi ${isSticky ? 'sticky' : ''}`}>
          <Container>
          <Navbar.Brand href="#" className="header-logo"><Image src={Logo} alt=""/></Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" className="ms-auto" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link href="/about">ABOUT</Nav.Link>
                <Nav.Link href="/offers">OFFERS</Nav.Link>
                <Nav.Link href="/restaurant">RESTAURANT</Nav.Link>
                <Nav.Link className='header-nav' href="/">
                  <Image src={Logo} alt=""/>
                </Nav.Link>
                <Nav.Link href="/prices">PRICES</Nav.Link>
                <Nav.Link href="/contact">CONTACT</Nav.Link>
                <Nav.Link href="/login">{user.result ? "DASHBOARD" : "LOGIN" }</Nav.Link>
                <Nav>
                  <Drawer name="end" placement={'end'} {...props}/>
                </Nav>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        {/* <nav className={`navbar navbar-expand-md main-nav-sidi ${isSticky ? 'sticky' : ''}`}>
          <div className="container-fluid">
            <a className="navbar-brand d-md-none" href="#">
              <img src="https://codingyaar.com/wp-content/uploads/logo-1.png" alt=""/>
            </a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav mx-auto">
                <li className="nav-item">
                  <a className="nav-link active" href="/about" aria-current="page" >ABOUT</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/offers">OFFERS</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/restaurant">RESTAURANT</a>
                </li>
                <a className="navbar-brand d-none d-md-block" href="/">
                  <img src={Logo} alt=""/>
                </a>
                <li className="nav-item">
                  <a className="nav-link" href='/prices'>PRICES</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href='/contact'>CONTACT</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href='/login'>LOGIN</a>
                </li>
                <li className="nav-item">
                  <Drawer name="end" placement={'end'} itemInCart={itemInCart} {...props}/>
                </li>
              </ul>
            </div>
          </div>
        </nav> */}
      </>
    );
}

export default Header;

