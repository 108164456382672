
import React from "react"
import Header from "../../Components/Header/Header";
import Blog from "./Blog";
import Footer from "../../Components/Footer/Footer";
import Intro from "./Intro";
import Station from "./Station";
import Team from "./Team";
import Gallery from "./Gallery";
import Condition from "./Condition";
import Surrounding from "./Surrounding";


const index=()=>{
    return (
        <>
        <Header/>
        <React.Fragment>
            <Intro/>
            <Station/>
            <Team/>
            <Condition/>
            <Surrounding/>
            <Gallery/>
            <Blog/>
        </React.Fragment>
        <Footer/>
        </>
    )
}

export default index;