import React, { useState,useEffect,useRef, useContext } from 'react';

import axios from 'axios';
import { Link , useNavigate} from "react-router-dom";

import "../Login/Login.css"
import "../Home/Home.css"
import { UserContext } from '../../Context/UserContext';

const Login = () => {
    const { setUser, setIsManualBooking, setIsAdmin } = useContext(UserContext);
    const[lemail,SetLEmail]=useState("");
    const[Lpassword,SetLPassword]=useState("");
    const[fname,SetFullname]=useState("");
    const[email,SetEmail]=useState("");
    const[password,SetPassword]=useState("");
    const[repassword,Setrepassword]=useState("");
    const navigate = useNavigate();

    const histoty = useNavigate()

useEffect(()=>{
    const auth=localStorage.getItem('user')
    if(auth){
        const user = JSON.parse(auth);
        setUser(user);
        setIsManualBooking(true);
        setIsAdmin(user.result.fname.toLowerCase() === 'felix')
        histoty('/bookings')
    }
})
const formRef= useRef();

const handleSubmit = async (e,formRef)=>{
    e.preventDefault();
    // const form = document.forms.login
    const obj = {
        email:formRef.current.email.value,
        password:formRef.current.password.value,
    }
    
    try {
        const rawResponse = await fetch(`${process.env.REACT_APP_BACKEND}/api/login`, {
            method: 'POST',
            headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
            },
            body: JSON.stringify(obj)
        });

        if(!rawResponse.ok) {
            throw new Error(rawResponse.statusText);
        }
        const content = await rawResponse.json();
        setUser(content);
        setIsManualBooking(true);
        setIsAdmin(content.result.fname.toLowerCase() === 'felix')
        localStorage.setItem("user",JSON.stringify(content));
        if(content.status == 'success'){
            histoty('/bookings')
        }
    } catch (error) {
        console.log(error)
        alert('invalid user nmae or pasword')
        setIsManualBooking(false);
        setIsAdmin(false);
    }


}

    return (
        <>
            <section>
                <div className='container'>
                    <div className='row mx-auto wrapper py-5'>

                        <div className='col-lg-6 '>
                            <div className="card">
                                <div className="card-body login-card-body">
                                    <p className="login-box-msg">Sign in to start your session</p>
                                    <form ref={formRef} onSubmit={(e) => handleSubmit(e,formRef)}>
                                        <div className="input-group mb-3">
                                            <input type="text" className="form-control" name='email' placeholder="Email"    />
                                            <div className="input-group-append">
                                                <div className="input-group-text">
                                                    <span className="fas fa-envelope" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="input-group mb-3">
                                            <input type="password" className="form-control" name='password' placeholder="Password"  />
                                            <div className="input-group-append">
                                                <div className="input-group-text">
                                                    <span className="fas fa-lock" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-8">
                                                <div className="icheck-primary">
                                                    <input type="checkbox" id="remember" />
                                                    <label htmlFor="remember">
                                                        Remember Me
                                                    </label>
                                                </div>
                                            </div>
                                            {/* /.col */}
                                            <div className="col-4">
                                                <button type="submit" className="btn btn-primary btn-block">Sign In</button>
                                            </div>
                                            {/* /.col */}
                                        </div>
                                        </form>

                                </div>
                                {/* /.login-card-body */}
                            </div>

                        </div>
                        <div className='col-lg-6'>
                            <div class="">
                                <div class="card card-outline card-primary">
                                    <div className="card-body">
                                        <p className="login-box-msg">Register a new membership</p>
                                            <div className="input-group mb-3">
                                                <input type="text" className="form-control" placeholder="Full name" value={fname} onChange={(e)=>SetFullname(e.target.value)}  />
                                                <div className="input-group-append">
                                                    <div className="input-group-text">
                                                        <span className="fas fa-user" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="input-group mb-3">
                                                <input type="email" className="form-control" placeholder="Email" value={email} onChange={(e)=>SetEmail(e.target.value)}  />
                                                <div className="input-group-append">
                                                    <div className="input-group-text">
                                                        <span className="fas fa-envelope" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="input-group mb-3">
                                                <input type="password" className="form-control" placeholder="Password" value={password} onChange={(e)=>SetPassword(e.target.value)} />
                                                <div className="input-group-append">
                                                    <div className="input-group-text">
                                                        <span className="fas fa-lock" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="input-group mb-3">
                                                <input type="password" className="form-control" placeholder="Retype password" value={repassword} onChange={(e)=>Setrepassword(e.target.value)}  />
                                                <div className="input-group-append">
                                                    <div className="input-group-text">
                                                        <span className="fas fa-lock" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                {/* <div className="col-8">
                                                    <div className="icheck-primary">
                                                        <input type="checkbox" id="agreeTerms" name="terms" defaultValue="agree" />
                                                        <label htmlFor="agreeTerms">
                                                            I agree to the <a href="#">terms</a>
                                                        </label>
                                                    </div>
                                                </div> */}
                                                {/* /.col */}
                                                <div className="col-4">
                                                    <button type="submit" className="btn btn-primary btn-block">Register</button>
                                                </div>
                                                {/* /.col */}
                                            </div>
                                        
                                    </div>

                                </div>
                            </div>

                        </div>

                    </div>

                </div>
            </section>

        </>
    );
}

export default Login;
