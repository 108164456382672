
import React from "react"
import Header from "../../../Components/Header/Header";
import Footer from "../../../Components/Footer/Footer";
import Intro from "./Intro";
import Desc from "./Desc";
import Gallery from "./Gallery";




const index=()=>{
    return (
        <>
        <Header/>
        <React.Fragment>
            <Intro/>
            <Desc/>
            <Gallery/>
        </React.Fragment>
        <Footer/>
        </>
    )
}

export default index;