
import React from "react"
import Header from "../../Components/Header/Header";
import Blog from "../Home/Blog";
import Footer from "../../Components/Footer/Footer";
import Intro from "./Intro";
import ContactForm from "./Form";
import GettingThere from "./GettingThere"


const index=()=>{
    return (
        <>
        <Header/>
        <React.Fragment>
            <Intro/>
            <GettingThere/>
            <ContactForm/>
        </React.Fragment>
        <Footer/>
        </>
    )
}

export default index;