import { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { UserContext } from './Context/UserContext';

function ScrollToTop() {
  const { pathname } = useLocation();
  const { cart, setCart,isManualBooking, setIsAdmin, setUser } = useContext(UserContext)
  // const [cart,setCart] = useState([])
  useEffect(() => {
    // console.log(isManualBooking)
    const savedCart = localStorage.getItem(isManualBooking ? "cart:admin":"cart");
    if (savedCart && savedCart !== 'null') {
        const parsedCart = JSON.parse(savedCart).cart;
        setCart(parsedCart);
    }

    const user = JSON.parse(localStorage.user !== undefined ? localStorage.user : null);
    if(user != null && user.result){
      setIsAdmin(user.result.fname === 'felix')
      setUser(user)
    }

  }, [isManualBooking]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export default ScrollToTop;
