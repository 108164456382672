import React, { Component } from 'react';
import "../Home/Home.css"
import "../Restaurant/Restaurant.css"
import icon from "../../assets/restaurant.webp"
import menu1 from "../../assets/menu1.png"
import menu2 from "../../assets/menu2.png"



const Menu = () => {
    const imgStyle = {
        width: '100%',

    }
    return (
        <>
            <section className='yellow-bg-strips'></section>

            <section className='menu-sec py-5' id='menu'>
                <div class="container">

                    <div className='row'>
                        <div className='title py-4'>
                            <img src={icon} /><h1>MENU</h1>
                        </div>
                        <div className='white-frame px-4 py-4'>

                        <p className='sec-3-subhead'>IN THE RESTAURANT,</p>
                        <p className='sec-3-desc py-4'>Our local chefs cater for all dietary requirements and offer a mouth-watering choice of  organic and healthy Moroccan dishes. We also offer international healthy creative food with vegan and vegetarian options.</p>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-6'>
                        <img src={menu1} style={imgStyle}/>
                        </div>
                        <div className='col-lg-6'>
                        <img src={menu2} style={imgStyle}/>
                        </div>
                        
                    </div>
                </div>
            </section>
            <section className='food'></section>
        </>
    );
}

export default Menu;
