import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import "../Home/Home.css"
import { FaInstagram } from 'react-icons/fa';
import { Navbar, Nav, NavbarBrand, NavItem, Image } from 'react-bootstrap';
import Cat1 from "../../assets/cat-1.png"
import Cat2 from "../../assets/cat-2.png"
import Cat3 from "../../assets/cat-3.png"
import Cat4 from "../../assets/cat-4.png"
import Cat5 from "../../assets/cat-5.png"
const CategorySec = () => {
    const imgStyle = {
        width: '150px',
    }
    return (
        <>
            <div class="container py-5">
                <div class="row d-flex justify-content-center">
                    <div class="col-sm-2 mx-auto text-center">
                    <Link className='catehead' target={'_blank'} to='/prices#home'> 

                        <Image src={Cat1} style={imgStyle}/>
                            <h4 className='text-black catehead'>Surf</h4>
                        </Link>
                        <div class="dot "></div>
                    </div>
                    <div class="col-sm-2 mx-auto text-center">
                    <Link className='catehead' target={'_blank'} to='/prices#profile'> 

                        <Image src={Cat2} style={imgStyle}/>
                        <h4 className='text-black catehead'>windSurf</h4>

                        </Link>
                        <div class="dot"></div>
                    </div>
                    <div class="col-sm-2 mx-auto text-center">
                    <Link className='catehead' target={'_blank'} to='/prices#longer-tab'> 

                        <Image src={Cat3} style={imgStyle}/>
                        <h4 className='text-black catehead'>kitesurfing</h4>

                        </Link>
                        <div class="dot"></div>
                    </div>
                    <div class="col-sm-2 mx-auto text-center">
                    <Link className='catehead' target={'_blank'} to='/prices#contact'> 

                        <Image src={Cat4} style={imgStyle}/>
                        <h4 className='text-black catehead'>sup</h4>

                        </Link>
                        <div class="dot"></div>
                    </div>
                    <div class="col-sm-2 mx-auto text-center">
                        <Link className='catehead' target={'_blank'} to='/restaurant'>
                        <Image src={Cat5} style={imgStyle}/>
                        <h4 className='text-black catehead'>Restaurant</h4>

                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CategorySec;
