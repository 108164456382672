import React, { useState } from 'react';

export const UserContext = React.createContext({
    cart: [],
    setCart: () => {},
    isAdmin: false,
    setIsAdmin: () => {},
    setUser: () => {},
    showDrawer: () => {},
    setIsManualBooking: () => {},
    user: {},
    isManualBooking: false,
    displayDrawer: false,
});

export const UserContextProvider = (props) => {
    const [state, setState] = useState({
        cart: [],
        user: {},
        isAdmin: false,
        isManualBooking: false,
        displayDrawer: false,
    });

    const setCart = (item) => {
        // console.log('Updating cart:', item);
        setState(prevState => ({ ...prevState, cart: item }));
    };

    const setUser = (user) => {
        setState(prevState => ({ ...prevState, user: user }));
    };

    const setIsAdmin = (flag) => {
        setState(prevState => ({ ...prevState, isAdmin: flag }));
    };
    
    const showDrawer = (flag) => {
        setState(prevState => ({ ...prevState, displayDrawer: flag }));
    };

    const setIsManualBooking = (flag) => {
        setState(prevState => ({ ...prevState, isManualBooking: flag }));
    };

    return (
        <UserContext.Provider value={{ ...state, setCart, setUser, setIsAdmin, setIsManualBooking, showDrawer }}>
            {props.children}
        </UserContext.Provider>
    );
};
