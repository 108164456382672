
import React from "react"
import Header from "../../Components/Header/Header";
import Blog from "../Home/Blog";
import Footer from "../../Components/Footer/Footer";
import Intro from "./Intro";
import EatEnjoy from "./Eat-Enjoy";
import Gallery from "./Gallery";
import Menu from "./Menu";



const index=()=>{
    return (
        <>
        <Header/>
        <React.Fragment>
            <Intro/>
            <EatEnjoy/>
            <Gallery/>
            <Menu/>
           
        </React.Fragment>
        <Footer/>
        </>
    )
}

export default index;