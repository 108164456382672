
import React from "react"
import Header from "../../Components/Header/Header";
import About from "./About";
import CategorySec from "./CategorySec";
import HeroSec from "./HeroSec";
import Instagram from "./Instagram";
import Offers from "./Offers";
import Restaurant from "./Restaurant";
import Blog from "./Blog";
import Footer from "../../Components/Footer/Footer";


const index=()=>{
    return (
        <>
        <Header/>
        <React.Fragment>
            <HeroSec/>
            <CategorySec/>
            <About/>
            <Offers/>
            <Restaurant/>
            <Instagram/>
            <Blog/>
        </React.Fragment>
        <Footer/>
        </>
    )
}

export default index;