import React from 'react';
import { Tab } from 'react-bootstrap';

const CustomTabs = ({ id, title, imgsrc, ...props }) => {
    return (
        <Tab id={id} {...props}> {/* Pass the id explicitly here */}
            <img src={imgsrc} style={{ width: '350px' }} alt="tab-img" />
            <span className="ml-2">{title}</span>
        </Tab>
    );
};

export default CustomTabs;
