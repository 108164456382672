import { Button, Modal, TabContainer } from "react-bootstrap";
import axios from "axios";
import Moment from 'moment';
import { date_format, SIDI_DICTIONARY } from "../../utils/config";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { applyFormat } from "../../utils/helper";

function CalendarModel(props) {
  const base_url = process.env.REACT_APP_BACKEND
  const handleConfirm = async () =>  {
    axios.put(`${base_url}/api/confirmBooking/${props.bookingid}`).then(resp => {
      toast.success('Booking confirm.', {
        icon: '👏',
      })
    });
  }

  if(!props.data)
      return <></>
  return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Booking Detail
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>{props.bookingtitle}</h4>
          <div>
            <Table2 data={props.data}/>
          </div>
          <p>
            <Table data={props.data}/>
          </p>
        </Modal.Body>
        <Modal.Footer>
          {
            props.data.confirm !== "yes" && <Button onClick={handleConfirm}>Confirm</Button>
          }
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
}

const Table2 = (props) => {
  const data = props.data;
  if (!data) return <></>;

  const date_format = "DD-MM-YYYY"; // Define the date format here

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        gap: "16px",
        justifyContent: "space-between", // Ensures even spacing
        marginTop: "20px",
      }}
    >
      {Object.keys(data).map((x) => {
        if (x !== "detail" && x !== "_id" && x !== "__v") {
          if (x === "created_date" || x === "updated_date" || x === "payment_date") {
            if (data[x] !== "") {
              let d = Moment(data[x]).format(date_format);
              return (
                <div
                  key={x}
                  style={{
                    border: "1px solid #ddd",
                    borderRadius: "8px",
                    padding: "16px",
                    flex: "1 1 calc(33.33% - 16px)", // Ensures 3 cards per row
                    backgroundColor: "#f9f9f9",
                    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <p style={{ margin: "0", fontWeight: "bold", color: "#333" , textTransform: 'capitalize' }}>
                    {x.replace(/_/g, " ")}
                  </p>
                  <p style={{ margin: "8px 0", color: "#555" }}>{d}</p>
                </div>
              );
            }
          } else {
            return (
              <div
                key={x}
                style={{
                  border: "1px solid #ddd",
                  borderRadius: "8px",
                  padding: "16px",
                  flex: "1 1 calc(33.33% - 16px)", // Ensures 3 cards per row
                  backgroundColor: "#f9f9f9",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                }}
              >
                <p style={{ margin: "0", fontWeight: "bold", color: "#333" ,textTransform: 'capitalize' }}>
                  {x.replace(/_/g, " ")}
                </p>
                <p style={{ margin: "8px 0", color: "#555" }}>{data[x]}</p>
              </div>
            );
          }
        }
        return null;
      })}
    </div>
  );
};


const Table = (props) => {
  const data = props.data.detail;
  const [approved, setApproved] = useState(false);

  const handleApproved = (e) => {
    setApproved(e.target.checked);
    if (e.target.checked) {
      const id = e.target.dataset.id;
      const bookingid = e.target.dataset.bookingid;
      const payload = {
        id: id,
        bookingid: bookingid,
      };
      axios.post(`${process.env.REACT_APP_BACKEND}/api/confirmBookingItem/`, payload).then((resp) => {
        toast.success("Booking confirmed.", {
          icon: "👏",
        });
      });
    } else {
      console.log("not checked");
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
      {data.map((x, i) => (
        <div
          key={i}
          style={{
            border: "1px solid #ddd",
            borderRadius: "8px",
            padding: "16px",
            backgroundColor: "#fff",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          }}
        >
          <div style={{ display: "flex", alignItems: "center", marginBottom: "16px" }}>
            <input
              type="checkbox"
              data-id={props.data._id}
              data-bookingid={x.title}
              onChange={handleApproved}
              checked={x.approveByAdmin === true}
              style={{ marginRight: "8px" }}
            />
            <span style={{ fontWeight: "bold", color: "#333" }}>{x.title}</span>
          </div>

          <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
            {Object.keys(x).map((a, n) => {
              if (a !== "approveByAdmin" && a !== "category") {
                return (
                  <div key={n} style={{ display: "flex", justifyContent: "space-between" }}>
                    <span style={{ fontWeight: "bold", color: "#555" , textTransform: 'capitalize'}}>{a.replace(/_/g, " ")}:</span>
                    <span style={{ color: "#333" }}>{applyFormat(a, x[a])}</span>
                  </div>
                );
              }
              return null;
            })}
          </div>

          <div style={{ marginTop: "16px" }}>
            <p style={{ marginBottom: "8px" }}>Checkbox for internal management:</p>
            <input type="checkbox" />
          </div>
        </div>
      ))}
    </div>
  );
};
export default CalendarModel;