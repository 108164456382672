import React, { Component,useState, useEffect, useRef, useContext } from 'react';
import axios from 'axios';
import { date_format, days } from '../../utils/config';
import { addInCart, setDate, triggerNativeEventFor } from '../../utils/helper';
import { DatePicker } from 'antd';
import { toast } from 'react-hot-toast';
import moment from 'moment';
import { UserContext } from '../../Context/UserContext';

const CampsForm = ({type,title}) => {
    const {cart, setCart, isManualBooking, showDrawer } = useContext(UserContext);
    const category = type
    const [result,setResult] = useState([])
    const [peoples,setPeoples] = useState([]);
    const [people,setPeople] = useState();
    const [accoumodationType,setAccomodationType] = useState([]);
    const [accoumodationTypeList,setAccomodationTypeList] = useState([]);
    const [duration,setDuration] = useState([]);
    const [durationList,setDurationList] = useState([]);
    const [accomodation,setAccomodation] = useState()
    const [isAccInc,setIsAccInc] = useState(false)
    const [amount,setAmount] = useState(0)
    let [campDate,setCampDate] = useState({
        startDate:undefined,
        endDate:undefined
    })
    const peopleRef = useRef();
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BACKEND}/api/getCamps/${category}`).then(resp => {
            setResult(resp.data.slice());
        });
    },[])

    useEffect(() => {
        const getAccomodations = async () => {
            let result = await fetch(`${process.env.REACT_APP_BACKEND}/api/hotel/getAll`);
            result = await result.json();
            setAccomodationType(result);
          }
        getAccomodations();
    }, [])
    
    useEffect(() => {
        if(result.length>0){
            const list = result.map(x => x.people)
            const dr = result.map(x => x.duration)
            setPeoples([...new Set(list)])
            setDurationList([...new Set(dr)])
            triggerNativeEventFor(peopleRef.current,{ event: 'change', value: 0})
        }
    },[result])

    const handleAccomodotion = (e) => {
        setAccomodation(e.target.value)
    }

    const handlePeople = (e) => {
        setPeople(e.target.value);
    }
    
    const handleDuration = (e) => {
        setDuration(e.target.value);
    }

    const getData = () => {
        let data;
        if(accomodation && people) 
            data = result.find(x => x.people == people && accomodation == x.accomodationType);
        else
            data = result.find(x => x.people == people);
        if(data){
            setIsAccInc(data.accomodation == 'yes' ? true : false);
            setAmount(data.price);
            setCampDate(data.campDate);
            //setAccomodation(undefined)
        }
    }

    useEffect(() => {
        if(people > 0){
            const acc = result.filter(x => x.people == people).filter(x => accoumodationType.map(a => a._id == x.accomodationType))
            const type = []
            acc.map(x => 
                accoumodationType.map(a => {
                    if(x.accomodationType == a._id)
                        if(!type.find(g => g._id === a._id))
                            type.push({'_id':a._id,'name':a.name})
                })
            )
            setAccomodationTypeList(type)
        }else{
            triggerNativeEventFor(peopleRef.current,{ event: 'change', value: 0})
        }
        getData();
    },[people,accomodation])

    const handleSubmit = async (e) => {
        e.preventDefault();
        const form = document.forms.campForm
        if(amount <= 0){
            toast.error('please select camp')
            triggerNativeEventFor(peopleRef.current,{ event: 'change', value: 0})
            return;
        }else if(campDate.startDate === undefined || campDate.startDate === ""){
            toast.error('please select camp date!.')
            return;
        }
        //campStartDate:setDate(form.campStartDate.value),
        const details = {
                category:category,
                title:title,
                people:form.people.value,
                duration:form.duration.value,
                campDates:campDate,
                amount:amount
            }
        
        const itemInCart = await addInCart(details,category, isManualBooking);
        if(itemInCart.status){
            setCart([...cart,details])
            toast.success('Item successfully add in cart.', {
                icon: '👏',
            });
            showDrawer(true);
        }else{
            toast.error(itemInCart.message)
        }
    }

    return(
        <form name="campForm" onSubmit={handleSubmit}>
            <div className="form-group">
                <label>Duration</label>
                <select className="form-control" name='duration' onChange={handleDuration}>
                <option value={''}>Select Option</option> 
                {durationList.length > 0 &&
                    durationList.map((n,i) => <option key={i} value={n}>{days[n]}</option>)
                }
                </select>
            </div>
            
            <div className="form-group">
                <label>No of Person</label>
                <select className="form-control" name='people' ref={peopleRef} onChange={handlePeople}>
                    <option value={''}>Please Select Person</option>
                {peoples.length > 0 &&
                    peoples.map(n => <option key={n} value={n}>{n} Person</option>)
                }
                </select>
            </div>
            {isAccInc &&
                <div className="form-group">
                    <label>ACCOMODATION</label>
                    <select className="form-control" name='accomodationType' onChange={handleAccomodotion}>
                    <option value={''}>Select Option</option> 

                        {accoumodationTypeList.map((accomodation) => (
                        <option key={accomodation._id} value={accomodation._id}>
                            {accomodation.name}
                        </option>
                        ))}
                    </select>
                </div>
            }
                {/* <div class="form-group">
                    <label>Date:</label>
                    <DatePicker
                        name="campStartDate"
                        format={date_format}
                    />
                </div> */}
                <div class="form-group">
                    <label>Camp Date : {moment(campDate?.startDate).format(date_format)} To {moment(campDate?.endDate).format(date_format)}</label>
                </div>
                <div class="form-group">
                <label>Total Amount: <span className='yellow-bubble'>€{amount}</span></label>
                <p> <b> This Will be the  price  of  whole inclusive Camp</b></p>

                </div>
                <button type="submit" className="btn book-now-btn"> <i className='fas fa-suitcase px-2'></i>ADD TO YOUR TRAVEL</button>

            </form>
    )
}

export default CampsForm;