import React, { Component, useContext, useRef } from 'react';
import "../Home/Home.css"
import "./Prices.css"
import icon from "../../assets/car.png"
import Cmap from "../../assets/contact-map.webp"
import { useEffect ,useState} from 'react';
import axios from 'axios';
//import { Calendar } from 'react-calendar';
import { date_format, peoples } from '../../utils/config';
import { addInCart, setDate, triggerNativeEventFor } from '../../utils/helper';
import { DatePicker } from 'antd';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import toast from 'react-hot-toast';
import { UserContext } from '../../Context/UserContext';

const Transfers = () => {
    const {cart, setCart, isManualBooking, showDrawer } = useContext(UserContext);
    const [airports,setAirports] = useState([]);
    const [response,setResponse] = useState([]);
    const [transferType,setTransferType] = useState("oneway")
    const [people,setPeople] = useState(1)
    const [location,setLocation] = useState()
    const [amount,setAmount] = useState(0)
    const airportRef = useRef()
    const transfersRef = useRef();
    let [arrivalDeparture,setArrivalDeparture] = useState({
        startDate:new Date(),
        endDate:new Date(),
    })
    const imgStyle = {
        height: '85px',
    }

    const getUnique = (list,type=transferType) => {
        var distinct = []
        for (var i = 0; i < list.length; i++)
            if (!distinct.find(x => x.name == list[i].name))
                distinct.push({name:list[i].name,value :list[i].name,price:type==="twoway"?list[i].price*2:list[i].price})
        return distinct;
    }

    useEffect(()=>{
        axios.get(`${process.env.REACT_APP_BACKEND}/api/getAirports`).then(resp => {
            setResponse(resp.data.slice());
            getUnique(resp.data)
        });
    },[])

    const filterPeople = (number) =>{
        return response.filter(x => x.people == number)
    }

    useEffect(() => {
        const list = getUnique(filterPeople(people),transferType);
        setAirports(list);
    },[transferType,people])

    useEffect(() => {
        if(airports.length > 0 && location){
            triggerNativeEventFor(airportRef.current,{ event: 'change', value: location})
        }
    },[airports,location])

    const handleOptionChange = changeEvent => {
        setTransferType(changeEvent.target.value);
    };

    const handlePeople = e =>{
        setPeople(e.target.value)
    }

    const handleEvent = (event, picker) => {
        setArrivalDeparture(picker)
        //console.log(picker.startDate,picker.endDate);
    }

    const handleCallback = (start, end, label) => {
        //console.log(start, end, label);
    }

    const handlePrice = e => {
        const price = e.target[e.target.selectedIndex].dataset.price
        setLocation(e.target.value)
        setAmount(price);
    }

    const bookNow = async (e) => {
        e.preventDefault();
        const form = document.forms.transfer
        const dateKey = transferType == "oneway" ? 'dateOfArrival' : 'dateOfArrivalAndDeparture';
        if(amount <= 0){
            toast.error('please select Transfer first')
            return;
        }
        if(transferType == "oneway"){
            if(form.dateOfArrival.value === undefined || form.dateOfArrival.value === "")
            {
                toast.error('please select Transfer date!.')
                return;
            }
        }else{
            if(arrivalDeparture.startDate === undefined || arrivalDeparture.startDate === "")
            {
                toast.error('please select Transfer date!.')
                return;
            }
        }
        
        const details = 
            {
                category:'transfer',
                title:'Transfer',
                tripDetail:transferType,
                people:form.people.value,
                pickupLocation:form.location.value,
                [dateKey]:transferType == "oneway" ? setDate(form.dateOfArrival.value) : `${setDate(arrivalDeparture.startDate)} to ${setDate(arrivalDeparture.endDate)}` ,
                flightNumber:form.flightNumber.value,
                amount:amount
            }
        const itemInCart = await addInCart(details,details.category, isManualBooking);
        if(itemInCart.status){
            setCart([...cart,details])
            toast.success('Item successfully add in cart.', {
                icon: '👏',
              });
            showDrawer(true);
        }else{
            toast.error(itemInCart.message)
        }
    }
    
    useEffect(() => {
    const timer = setTimeout(() => {
        if (window.location.hash === "#transfers") {
            if(transfersRef.current){
                transfersRef.current.scrollIntoView();
            }
        }
        }, 1500);
    return () => clearTimeout(timer);
    }, []);

    return (
        <>
            
            <section className='lessons-sec-5 py-5' id='transfers' ref={transfersRef}>
                <div class="container">

                    <div className='row' >
                        <div className='title py-4'>
                            <img src={icon} style={imgStyle} /><h1>TRANSFERS</h1>
                        </div>
                        <p className='sec-3-desc'>We offer comfortable transfer services from nearby airports of Agadir, Marrakesh, and Essaouira. Price is shown per vehicle.
</p>
                        <p className='sec-3-subhead'>PLEASE CHOOSE:
                        </p>
                        <form name='transfer' onSubmit={bookNow}>
                            <div className="row">
                                <div className="col-sm-4">
                                    <div className="form-group">
                                        <label>Trip Details</label>
                                        <div className="radio">
                                        <label>
                                            <input type="radio" value="oneway" 
                                                        checked={transferType === 'oneway'} 
                                                        onChange={handleOptionChange} />
                                            One Way
                                        </label>
                                        </div>
                                        <div className="radio">
                                        <label>
                                            <input type="radio" value="twoway" 
                                                        checked={transferType === 'twoway'} 
                                                        onChange={handleOptionChange} />
                                            Two Way
                                        </label>
                                        </div>
                                        

                                    </div>

                                </div>
                                <div className="col-sm-4">
                                    <div className="form-group">
                                        <label>No of Person</label>
                                        <select className="form-control" name='people' onChange={handlePeople}>
                                            <option key={'11'} value={''}>Please Select Person</option>
                                        {
                                            peoples.map(n => <option key={n} value={n}>{n} Person</option>)
                                        }
                                        </select>
                                    </div>

                                </div>
                                <div className="col-sm-4">
                                    <div className="form-group">
                                        <label>Please choose Your Airport</label>
                                        <select className="form-control" name='location' ref={airportRef} onChange={handlePrice}>
                                        <option key={'11'} value={''}> Select Option </option>

                                            {
                                                airports.map((option,i) => <option key={i} data-price={option.price} value={option.value}>{option.name}({option.price})</option>)
                                            }
                                        </select>
                                    </div>
                                    {/* text input */}

                                </div>
                            </div>
                            <div className="row">
                            {transferType == "oneway" && 
                                <div className="col-sm-4">
                                    <div className="form-group">
                                        <label>Date of Arrival</label>
                                        <DatePicker
                                            name="dateOfArrival"
                                            format={date_format}
                                        />
                                        {/* <div className="input-group date" id="reservationdatetime" data-target-input="nearest">
                                            <input type="text" name='dateOfArrival' className="form-control datetimepicker-input" data-target="#reservationdatetime" />
                                            <div className="input-group-append" data-target="#reservationdatetime" data-toggle="datetimepicker">
                                                <div className="input-group-text"><i className="fa fa-calendar" /></div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            }        
               {transferType == "twoway" && 
  <div className="col-sm-4">
    <div className="form-group">
      <label>Date of Arrival & Departure</label>
      <div className="input-group">
        <DateRangePicker
          initialSettings={{
            startDate: moment().format("DD-MM-YYYY"), // Set the default start date
            endDate: moment().add(1, "days").format("DD-MM-YYYY"), // Set the default end date
            locale: {
              format: "DD-MM-YYYY", // Enforce the DD-MM-YYYY format
              applyLabel: "Apply",
              cancelLabel: "Cancel",
              daysOfWeek: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
              monthNames: [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December",
              ],
            },
          }}
          onCallback={(start, end) => {
            console.log(`Range Selected: ${start.format("DD-MM-YYYY")} - ${end.format("DD-MM-YYYY")}`);
          }}
        >
          <input 
            placeholder="Select Date Range" 
            className="form-control" 
            readOnly // Prevent user edits directly
          />
        </DateRangePicker>
      </div>
    </div>
  </div>
}
 {/* {transferType == "twoway" && 
                                <div className="col-sm-4">
                                    <div className="form-group">
                                        <label>Date of Arrival & Departure</label>
                                        <div className="input-group">
                                            
                                            <DateRangePicker format={date_format} onEvent={handleEvent} onCallback={handleCallback}>
                                                <input />
                                            </DateRangePicker>
                                            
                                        </div>
                                    </div>
                                </div>
                            } */}



                                
                                <div className="col-sm-4">
                                    <div class="form-group">
                                        <label>Flight Number</label>
                                        <input type="text" class="form-control" name='flightNumber' placeholder="Enter ..." />
                                    </div>
                                    {/* text input */}

                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div class="form-group">
                                    <label>Total Transfer Amount :  <span className='yellow-bubble'>€{amount}</span></label>
                                </div>
                            </div>
                            <button type="submit" className="btn book-now-btn"> <i className='fas fa-suitcase px-2'></i>ADD TO YOUR TRAVEL</button>




                        </form>

                    </div>



                </div>
            </section>
            <section className='Cmap'></section>
        </>
    );
}

export default Transfers;
