
import React, { useContext } from "react"
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import Intro from "./Intro";
import { UserContext } from "../../Context/UserContext";


const Index=()=>{
    const {cart, setCart} = useContext(UserContext);
    return (
        <>
        <Header/>
        <React.Fragment>
            <Intro itemInCart={cart}/>
            
        </React.Fragment>
        <Footer/>
        </>
    )
}

export default Index;