import React, { Component , useState } from 'react';
import "../Home/Home.css"
import './About.css'
import { FaInstagram } from 'react-icons/fa';
import { Navbar, Nav, NavbarBrand, NavItem } from 'react-bootstrap';
import camel from "../../assets/camel2.webp"
import tree from "../../assets/three-trees.webp"
import iconcustom2 from "../../assets/icon-custom2.webp"

import icon from "../../assets/restaurant.webp"
import icon2 from "../../assets/package-icon.webp"
import icon3 from "../../assets/customize-icon.webp"
import viewmore from "../../assets/view-more.webp"
import Station1 from "../../assets/surrounding2.jpg"
import Station2 from "../../assets/surrounding1.jpg"


const Surrounding = () => {
    const imgStyle = {
        height: '85px',

    }
    const styles ={
        width:'100%',
    }
    const [showSection, setShowSection] = useState(false);
    const toggleSection = () => {
        setShowSection(!showSection);
      };

    return (
        <>
        
            <div>
                    <img src={Station1} style={styles}/>

            <section className='surrounding-sec py-5' id='surrounding'>
                <div class="container">
                <div class="title">
                    <h1>SURROUNDINGS</h1>
                </div>
                <div className='white-frame px-4  py-4'>
                    <h4>MOROCCO</h4>
                    <p>You are approximately only 4 airplane hours and a couple of mouse clicks away from your dream spot. Morocco
has everything: an impressive piece of nature with tall mountains, and enormous desert, many wonderful oasis,
never ending Atlantic beaches, winds to make you delirious and least of all… The fantastic waves! </p>
<br/>
<p>Yes, this old but very lively nature will encircle you magically with it’s beautiful architecture, handmade artistry,
twirling cities with exquite gardens, secret alleys and puzzling marketplaces on their idyllic locations.Discover
the many colours, smells and sounds of Morocco!</p>
<br/>
<h4>SIDI KAOUKI</h4>
<p>Sidi Kaouki is located 150 km North from Agadir and 28 km South Essaouira, directly on the Atlantic coast. This
easy-going town has been known for a wonder healer called Kaouki, who lived in the Marabout. People have
been coming to this town for over 500 years and up until now, the beach has not lost any of it’s mystical power. </p>
<br/>
<p>This piece of earth is known worldwide for it’s dreamy surf possibilities. It is the crème d’crème for
enthusiastically wavers. In the 5 km long bay you will find enough spots to ride on the clean shore broken
waves, which are between 1-3 m high. There’s fine sand beach as far as the eyes can take you!</p>
<br/>
<h4>ESSAOUIRA</h4>
<p>The next largest city is Essaouira, also called the pearl of Morocco. Artists from the whole world gather here
enjoying the history and charm of this city. You will enjoy all activities in the small streets and marketplaces of
the Medina.</p>
<br/>
<p>Enjoy the town’s beach, see the fishermen at the port with their blue wooden boats and explore the narrow
streets of the old Medina. You will find many small cafeterias and restaurants, from gourmet to very affordable
local traditional meals. There are lots of yearly music festivals in Essaouira, as well as art exhibitions and
galleries. And don't forget the argan oil produced in this area! You can easily get to Essaouira with local busses
that run every other hour, or you can take a taxi.</p>
<br/>
<br/>

</div>
                </div>
            </section>
            <img src={Station2} style={styles}/>
           
                 </div>
          
        </>
    );
}

export default Surrounding;
