import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import axios from "axios";
import './checkout.css'

import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import wave from "../../assets/wave.png"
import { getCartAmount, getCoupon, saveCoupon } from "../../utils/helper";
import { toast } from "react-hot-toast";
import Payment from '../../assets/payment.png'
import { UserContext } from "../../Context/UserContext";

export default function CheckoutForm({ amount }) {
  const imgStyle = {
    width: '500px',

  }
  const { isManualBooking, user, isAdmin } = useContext(UserContext);
  // console.log(user, isAdmin)
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [clientSecret, setClientSecret] = useState('');
  const [couponCode, setCouponCode] = useState('');
  const [isCouponLoading, setIsCouponLoading] = useState(false);
  const [couponApplied, setCouponApplied] = useState(false);
  const [couponAmount, setCouponAmount] = useState(getCoupon());
  const [couponPercentage, setCouponPercentage] = useState(0);
  const [cartAmount, setCartAmount] = useState(getCartAmount(isManualBooking));
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const [id, setId] = useState(undefined);
  useEffect(() => {
    if (amount > 0) {
      try {
        if (user?.result) {
          setDisabled(false);
        }
      } catch (e) {
        console.log(e)
      }
      // Create PaymentIntent as soon as the page loads
      // const baseUrl = `${process.env.REACT_APP_BACKEND}/api/checkout`;
      // let itemInCart = JSON.parse(localStorage.getItem("cart"));
      // if (itemInCart) {
      //   itemInCart = itemInCart.cart;
      // }
      // window.fetch(baseUrl, {
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "application/json"
      //   },
      //   body: JSON.stringify({ cartItems: itemInCart, amount: amount })
      // }).then(res => {
      //   return res.json();
      // }).then(data => {
      //   setId(data.result._id)
      //   setClientSecret(data.clientSecret);
      // });
      createPaymentIntent(amount);
    }
  }, []);

  const createPaymentIntent = async (finalAmount) => {
    try {
      let itemInCart = JSON.parse(localStorage.getItem("cart"));
      if (itemInCart) {
        itemInCart = itemInCart.cart;
      }
      window.fetch(`${process.env.REACT_APP_BACKEND}/api/checkout`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ cartItems: itemInCart, amount: finalAmount, couponCode: couponCode, couponAmount: couponAmount })
      }).then(res => {
        return res.json();
      }).then(data => {
        setId(data.result._id)
        setClientSecret(data.clientSecret);
      });
    } catch (error) {
      console.error("Error creating PaymentIntent:", error);
      toast.error("Failed to create payment intent.");
    }
  };

  const cardStyle = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#32325d"
        }
      },
      invalid: {
        fontFamily: 'Arial, sans-serif',
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    }
  };

  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };

  const handleSubmitType2 = async ev => {
    ev.preventDefault();
    setProcessing(true);
    const form = document.forms.paymentform
    if (form.email.value != "") {
      const data = {
        customer_firstname: form.customer_firstname.value,
        customer_lastname: form.customer_lastname.value,
        email: form.email.value,
        amount: amount,
        id: id
      }
      const baseUrl = `${process.env.REACT_APP_BACKEND}/api/sendPaymentLink`;
      const result = await axios.post(baseUrl, data);
      if (result.status === 200) {
        localStorage.setItem('cart', null)
        toast.success('Payment successfully received.', {
          icon: '👏',
        })
        //navigate('/')
      }

      setError(null);
      setProcessing(false);
      setSucceeded(true);
    } else {
      toast.error('Email is required.');
    }

  }

  const handleSubmit = async ev => {
    ev.preventDefault();
    setProcessing(true);

    const payload = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement)
      }
    });

    if (payload.error) {
      setError(`Payment failed ${payload.error.message}`);
      setProcessing(false);
    } else {
      const form = document.forms.paymentform
      const data = {
        customer_firstname: form.customer_firstname.value,
        customer_lastname: form.customer_lastname.value,
        city: form.city.value,
        street: form.street.value,
        country: form.country.value,
        postCode: form.postCode.value,
        phone: form.phone.value,
        email: form.email.value,
        notes: form.notes.value,
        id: id
      }
      const baseUrl = `${process.env.REACT_APP_BACKEND}/api/updatePayment`;
      const result = await axios.post(baseUrl, data);
      if (result.status === 200) {
        localStorage.setItem('cart', null)
        toast.success('Payment successfully received.', {
          icon: '👏',
        })
        //navigate('/')
      } else {
        toast.error('Something trouble to payment.')
      }

      setError(null);
      setProcessing(false);
      setSucceeded(true);
    }
  };

  const verifyCoupon = async (e) => {
    e.preventDefault();
    setIsCouponLoading(true);
    try {
      let response = await fetch(`${process.env.REACT_APP_BACKEND}/api/coupon/checkCouponValidity/${couponCode}`);

      // Check if the response is ok (status code in the range 200-299)
      if (!response.ok) {
        throw new Error(`Server error: ${response.status}`);
      }

      let result = await response.json();
      console.log(result);
      // Check if a coupon was found
      if (result && result?.code) {
        setCouponApplied(true);
        setCouponPercentage(result.amount);
        let coupon = saveCoupon(couponCode, result.amount, isManualBooking);
        setCouponAmount(coupon.discount);
        const cAmt = getCartAmount(isManualBooking);
        setCartAmount(cAmt);
        createPaymentIntent(cAmt);
        toast.success(`Coupon Applied`)
        // console.log("Coupon is valid:", couponCode);
      } else {
        toast.error(`Coupon is invalid or not active.`)
      }
    } catch (error) {
      toast.error(`Error verifying coupon: ${error.message}`)
      // Handle 500 or other errors here
      console.error("Error verifying coupon:", error.message);
    }
    setIsCouponLoading(false);
  }

  return (
    <>
      <div class="jumbotron jumbotron-fluid sidi-hero hero-sec-one contact-hero">
        <div class="container">
          <div className='sidi-hero-slider'>
            <div className='row'>
              <div className='col-lg-6'>
                <h1 class="text-black">Checkout</h1>
                <img src={wave} style={imgStyle} />

              </div>
              <div className='col-lg-6 '>


              </div>

            </div>
          </div>
        </div>
      </div>

      <form id="payment-form" name="paymentform" onSubmit={!isAdmin ? handleSubmit : handleSubmitType2}>
        <section className='py-5' >
          <div className='container'>
            <div className='row'>
              <div className='col-lg-7'>

                <h3>Billing Details</h3>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">First Name</label>
                  <input type="text" className="form-control" id="exampleInputEmail1" placeholder=" First Name" name="customer_firstname" />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Last Name</label>
                  <input type="text" className="form-control" id="exampleInputEmail1" placeholder=" Last Name" name="customer_lastname" />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Company Name</label>
                  <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Company Name" name="city" />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Street Address</label>
                  <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Street Address" name="street" />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Town / City</label>
                  <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Town / City" name="town" />
                </div>

                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">State / Country</label>
                  <input type="text" className="form-control" id="exampleInputEmail1" placeholder="State / Country" name="country" />
                </div>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Postcode / Zip</label>
                  <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Postcode / Zip" name="postCode" />
                </div>

                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Phone</label>
                  <input type="text" className="form-control" id="exampleInputEmail1" placeholder="Phone" name="phone" />
                </div>

                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">Email Address</label>
                  <input type="email" className="form-control" id="exampleInputEmail1" placeholder="Email" name="email" />
                </div>

                <h3>Additional Information</h3>
                <p>Let us know if you have a special request or if there’s anything we can do! (optional)</p>
                <label htmlFor="exampleInputEmail1">Notes About the Booking</label>

                <div className="form-group">
                  <textarea rows={4} cols={100} name="notes" />
                </div>

              </div>

              <div className='col-lg-5'>
                  {!isAdmin &&
                  
                <div className="payment-card-box m-1">
                      <label htmlFor="coupon">Coupon</label>
                      <input type="text" className="form-control" value={couponCode} id="coupon" onChange={(e) => setCouponCode(e.target.value)} placeholder="Coupon Code" name="coupon" />
                    
                  <button
                    disabled={couponApplied || couponCode === ''}
                    id="coupon-submit"
                    onClick={(e) => verifyCoupon(e)}
                    >
                    <span id="button-text">
                      {isCouponLoading ? (
                        <div className="spinner" id="spinner"></div>
                      ) : (
                        couponApplied ? "Coupon Applied" : "Apply Coupon"
                      )}
                    </span>
                  </button>
                  {couponPercentage > 0 &&
                    <h3>You got {couponPercentage}% discount, Total discount is €{couponAmount}</h3>
                  }

                  {/* Show any error that happens when processing the payment */}
                  {(
                    <div className="card-error" role="alert">

                    </div>
                  )}
                </div>
                  
                }

                {/* Pyament section */}
                <div className="payment-card-box">
                  {!isAdmin && <CardElement id="card-element" options={cardStyle} onChange={handleChange} />}
                  <h3>Total Price:€{cartAmount}</h3>
                  <button
                    disabled={processing || disabled || succeeded}
                    id="submit"
                  >
                    <span id="button-text">
                      {processing ? (
                        <div className="spinner" id="spinner"></div>
                      ) : (
                        isAdmin ? "Send Invoice" : "Pay now"
                      )}
                    </span>
                  </button>
                  <img src={Payment} width={'250px'} className="mx-auto" />
                  {/* Show any error that happens when processing the payment */}
                  {error && (
                    <div className="card-error" role="alert">
                      {error}
                    </div>
                  )}
                  {/* Show a success message upon completion */}
                  <p className={succeeded ? "result-message" : "result-message hidden"}>
                    Payment succeeded, see the result in your
                    <a
                      href={`https://dashboard.stripe.com/test/payments`}
                    >
                      {" "}
                      Stripe dashboard.
                    </a> Refresh the page to pay again.
                  </p>

                </div>
              </div>

            </div>
          </div>
        </section>

      </form>
    </>
  );
}