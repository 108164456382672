
import React, { useContext, useState } from "react"
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import CheckoutForm from "./CheckoutFrom";
// Replace 'pk_test_...' with your own Stripe publishable key
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { getCartAmount } from "../../utils/helper";
import { STRIPE_PUBLISH_KEY } from "../../Constant";
import { UserContext } from "../../Context/UserContext";
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const promise = loadStripe(STRIPE_PUBLISH_KEY);

const Index = () => {
    const options = {
        // passing the client secret obtained from the server
        clientSecret: '{{CLIENT_SECRET}}',
    };
    const { isManualBooking } = useContext(UserContext);
    const amount = getCartAmount(isManualBooking);
    return (
        <>
            <Header />
            <React.Fragment>
                <Elements stripe={promise}>
                    <CheckoutForm amount={amount} />
                </Elements>
            </React.Fragment>
            <Footer />
        </>
    )
}

export default Index;