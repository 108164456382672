
import React from "react"
import Header from "../../Components/Header/Header";
import Blog from "../Home/Blog";
import Footer from "../../Components/Footer/Footer";
import Intro from "./Intro";
import Rentals from "./Rentals";
import Lessons from "./Lessons";
import Packages from "./Packages";
import Camps from "./Camps";
import Transfers from "./Transfers"
import Accomodation from "./Accomodation";



const Index=()=>{
    return (
        <>
        <Header/>
        <React.Fragment>
            <Intro/>
            <Rentals/>
            <Lessons/>
            <Accomodation/>
            <Transfers/>
            <Camps/>
            <Packages/>
           
        </React.Fragment>
        <Footer/>
        </>
    )
}

export default Index;