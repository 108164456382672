import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import '../index.css';
import { Editor } from "@tinymce/tinymce-react";
import { DropzoneArea } from 'material-ui-dropzone';

const NewCoupon = () => {
    const [code, setCode] = useState("");
    const [description, setDescription] = useState("test");
    const [amount, setAmount] = useState("");
    const [expiry, setExpiry] = useState("");
    const [count, setCount] = useState("");
    const [file, setFile] = useState();
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

    const submitCoupon = async (e) => {     
        e.preventDefault();
        setIsLoading(true);

        try {
            const userID = JSON.parse(localStorage.getItem('user')).result._id;
            const baseUrl = `${process.env.REACT_APP_BACKEND}/api/coupon/new-coupon`;
            const data = { code, description, amount, expiry, count, userID };

            const formData = new FormData();
            for (let name in data) {
                formData.append(name, data[name]);
            }
            formData.append("file", file);

            const result = await axios.post(baseUrl, formData, {
                headers: { 'Content-Type': 'multipart/form-data' }
            });
            
            if (result) {
                console.log(result);
                navigate('/coupons');
            }
        } catch (error) {
            console.error("Submission error:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const saveFile = (e) => {
        setFile(e.target.files[0]);
    };

    return (
        <div>
            <div className="content-wrapper">
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Add New Coupon</h1>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="content">
                    <form name='couponForm' onSubmit={submitCoupon} encType="multipart/form-data">    
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card card-primary">
                                        <div className="card-body">
                                            <div className="form-group">
                                                <label htmlFor="code">Coupon Code</label>
                                                <input type="text" className="form-control" id="code" placeholder="Enter code" value={code} onChange={e => setCode(e.target.value)} />
                                            </div>
                                            
                                            <div className="form-group">
                                                <label htmlFor="description">Description</label>
                                                <Editor
                                                    textareaName="description"
                                                    value={description}
                                                    onEditorChange={newValue => setDescription(newValue)}
                                                    init={{
                                                        height: 300,
                                                        menubar: false,
                                                        plugins: [
                                                            'advlist autolink lists link image charmap print preview anchor',
                                                            'searchreplace visualblocks code fullscreen',
                                                            'insertdatetime media table paste code help wordcount'
                                                        ],
                                                        toolbar: 'undo redo | formatselect | bold italic backcolor | ' +
                                                                 'alignleft aligncenter alignright alignjustify | ' +
                                                                 'bullist numlist outdent indent | removeformat | help',
                                                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                                    }}
                                                />
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="amount">Percntage(please don't enter percentage sign)</label>
                                                <input type="number" className="form-control" id="amount" placeholder="Enter percentage" value={amount} onChange={e => setAmount(e.target.value)} />
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="expiry">Expiry Date</label>
                                                <input type="date" className="form-control" id="expiry" value={expiry} onChange={e => setExpiry(e.target.value)} />
                                            </div>

                                            <div className="form-group">
                                                <label htmlFor="count">Count</label>
                                                <input type="number" className="form-control" id="count" placeholder="Enter count" value={count} onChange={e => setCount(e.target.value)} />
                                            </div>

                                            {/* <div className="form-group">
                                                <label htmlFor="featured-image">Featured Image</label>
                                                <input
                                                    accept="image/*"
                                                    id="thumbnail-upload"
                                                    type="file"
                                                    onChange={saveFile}
                                                />
                                            </div> */}
                                        </div>

                                        <div className="card-footer">
                                            <button type="submit" className="btn btn-primary" disabled={isLoading}>
                                                {isLoading ? "Saving..." : "Save"}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </section>
            </div>
        </div>
    );
}

export default NewCoupon;
