import React, { Component, useContext, useEffect, useState } from 'react';
import Header from '../Components/Header';
import Sidebar from '../Components/Sidebar';
import AccommodationForm from '../../Pages/Accomodations/AccommodationForm';
import { Footer } from 'antd/es/layout/layout';
import PackageForm from '../../Pages/Packages/PackageForm';
import Transfers from '../../Pages/Prices/Transfers';
import Rentals from '../../Pages/Prices/Rentals';
import Lessons from '../../Pages/Prices/Lessons';
import { Button, Col, Form, Modal, Row, Tabs } from 'react-bootstrap';
import CustomTabs from '../../Pages/Prices/CustomTabs';
import { getCartAmount, getIsOverrideCartAmt, getOverrideCartAmt, saveOverrideCartAmt } from '../../utils/helper';
import { useNavigate } from "react-router-dom";
import Intro from '../../Pages/Travel-Package/Intro';
import CheckoutForm from '../../Pages/Checkout/CheckoutFrom';
import { STRIPE_PUBLISH_KEY } from '../../Constant';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { UserContext } from '../../Context/UserContext';
import CampsForm from '../../Pages/Camps/CampsForm';
import toast from 'react-hot-toast';
const promise = loadStripe(STRIPE_PUBLISH_KEY);

const accomodations = [
    { _id: '6400f690b7da4be8a3efb26d', title: 'Abdla Apartments' },
    { _id: '6402fd477e80619a6a1bb621', title: 'Marabout' },
    { _id: '6402fd547e80619a6a1bb625', title: 'Feru' },
    { _id: '6402fd3b7e80619a6a1bb61d', title: 'Rehali Riads' },
    { _id: '6400f5f2b7da4be8a3efb255', title: 'Windy Kaouki' },
];

const Booking = () => {
    const { cart, setCart, setIsManualBooking, isManualBooking } = useContext(UserContext);
    const [packages, setPackages] = useState([]);
    const [isOverrideAmount, setIsOverrideAmount] = useState(getIsOverrideCartAmt());
    const [overrideAmount, setOverrideAmount] = useState(getOverrideCartAmt());
    const [cartAmount, setCartAmount] = useState(getCartAmount(true));
    const [displayCheckout, setDisplayCheckout] = useState(false);
    const [travelModal, setTravelModal] = useState(false);
    const [checkoutModal, setCheckoutModal] = useState(false);
    const [camps, setCamps] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        setIsManualBooking(true);
        getPackages();
        getCamps();
    }, [])

    const getPackages = async () => {
        let result = await fetch(`${process.env.REACT_APP_BACKEND}/api/package/getAll`);
        result = await result.json();
        setPackages(result);
    }

    const getCamps = async () => {
        let result = await fetch(`${process.env.REACT_APP_BACKEND}/api/camp/getAll`);
        result = await result.json();
        setCamps(result);
    }

    const handleSave = (e) => {
        e.preventDefault();
        console.log(isManualBooking,cart)
        if(cart.length === 0)
        {
            toast.error('Cart is empty.', {
                icon: '👏',
              })
              return;
        }
        saveOverrideCartAmt(overrideAmount);
        setCartAmount(getCartAmount(isManualBooking));
        setDisplayCheckout(true);
        setTravelModal(true);
    }

    return (
        <div className='wrapper'>
            <Header />
            <Sidebar />
            <div>
                <div className="content-wrapper">
                    <section className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1>Manual Booking</h1>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="content">
                        <Tabs defaultActiveKey="Lessons" id="fill-tab-example" className="mb-3" fill>
                            <CustomTabs eventKey="Lessons" title="Lessons">
                                <Lessons />
                            </CustomTabs>
                            <CustomTabs eventKey="Rentals" title="Rentals">
                                <Rentals />
                            </CustomTabs>
                            <CustomTabs eventKey="Transfers" title="Transfers">
                                <Transfers />
                            </CustomTabs>
                            <CustomTabs eventKey="Camps" title="Camps">
                                {camps.map((camp, index) => (
                                    <>
                                        <div style={{
                                            fontSize: '20px',
                                            paddingTop: '10px'
                                        }}>{camp.name}</div>
                                        <CampsForm key={index} type={camp._id} title={camp.name} />
                                    </>
                                ))}
                            </CustomTabs>
                            <CustomTabs eventKey="Accommodation" title="Accommodation">
                                {
                                    accomodations.map((item, i) => (
                                        <>
                                            <div style={{
                                                fontSize: '20px',
                                                paddingTop: '10px'
                                            }}>{item.title}</div>
                                            <AccommodationForm type={item._id} title={item.title} />
                                        </>
                                    ))
                                }
                            </CustomTabs>
                            <CustomTabs eventKey="Package" title="Package">
                                {
                                    packages.map((item, i) => (
                                        <>
                                            <div style={{
                                                fontSize: '20px',
                                                paddingTop: '10px'
                                            }}>{item.name}</div>
                                            <PackageForm key={i} type={item._id} title={item.name} />
                                        </>
                                    ))
                                }
                            </CustomTabs>
                        </Tabs>
                    </section>
                    <section className='content-footer'>
                        <div>
                            Total Price:€{cartAmount}
                        </div>
                        <div>
                            <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
                                <Form.Label column sm="2">
                                    Override Amount
                                </Form.Label>
                                <Col sm="10">
                                    <input type='checkbox' onChange={(e) => setIsOverrideAmount(e.target.value)} name='isOverrideAmount' id='isOverrideAmount' value={isOverrideAmount} />
                                </Col>
                            </Form.Group>
                            Amount : <input type='number' disabled={!isOverrideAmount} name='overrideAmount' id='overrideAmount' value={overrideAmount} onChange={(e) => setOverrideAmount(e.target.value)} />
                            <Button onClick={handleSave} variant="primary">Proceed & Checkout</Button>
                        </div>
                        <Modal
                            show={travelModal}
                            onHide={() => setTravelModal(false)}
                            size="lg"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter">
                                    Booking Detail
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Intro setTravelModal={setTravelModal} setCheckoutModal={setCheckoutModal} hideFeaturedImage={true} itemInCart={cart} />
                            </Modal.Body>
                            <Modal.Footer>
                                <Button onClick={() => setTravelModal(false)}>Close</Button>
                            </Modal.Footer>
                        </Modal>
                        <Modal
                            show={checkoutModal}
                            onHide={() => setCheckoutModal(false)}
                            size="lg"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <Modal.Header closeButton>
                                <Modal.Title id="contained-modal-title-vcenter">
                                    Booking Detail
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Elements stripe={promise}>
                                    <CheckoutForm amount={cartAmount} />
                                </Elements>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button onClick={() => setCheckoutModal(false)}>Close</Button>
                            </Modal.Footer>
                        </Modal>
                    </section>
                </div>
            </div>

            <Footer />
        </div>
    )
}

export default Booking;